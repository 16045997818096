import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../../components/property-map/property-map'
import GenericPageContainer from '../../../../GenericPageElements/GenericPageContainer'
import request from '../../../../helpers/request'
import hasPermission from '../../../../helpers/permissions'
import BatchRunModal from "../BatchRunModal";
import { useHistory } from 'react-router-dom';
// import { AddressBase, CMOSFormatted, CMOSLive, Summary, ValuationOffice } from './shared'
import { AddressBase, CMOSUPRN, CMOSVOA, IdenteqUPRN, IdenteqVOA, CMOSFormatted, CMOSFormattedVOA, CMOSLive, Summary, ValuationOffice } from './shared'
import { useTranslations } from '../../../../helpers/hooks'


export default view(function WholesalerGAPSWorkflow() {
  const history = useHistory();
  const postcode = history.location?.state?.postcode || null;
  const [summaryData, setSummaryData] = useState({})
  const translations = useTranslations('wholesaler_gaps')
  const [selectedMatches, setSelectedMatches] = useState({abp: {}, voa: {}})
  const [bulkModel, setBulkModel] = useState('');

  useEffect(() => {
    setSelectedMatches({abp: {}, voa: {}})
  }, [summaryData])
  return (
    <GenericPageContainer
    containerWidth="92vw"

      title={`${translations['module_title'] || ''} - Workflow`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div style={{marginBottom: '10px'}}>
          <div className="stage-block">
            <button className="active-stage">Stage 1</button>
            <button onClick={() => history.push({pathname: "/portal/wholesaler-voa-gaps", state: { postcode: postcode }})}>Stage 2</button>
            { hasPermission('WHOLESALER_GAPS', 'BULK-BUY') ?
              <button onClick={() => setBulkModel('batchrun')}>Stage 3</button>
            : 
              <button>Stage 3</button>
            }
            <button onClick={() => history.push({pathname: "/portal/commercial-gaps/", state: { postcode: postcode }})}>Stage 4</button>
          </div>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/wholesaler-gaps", state: { postcode: postcode }})}>To Summary</button>
          <button className="navigate" onClick={() => history.push({pathname: "/portal/wholesaler-gaps/search", state: { postcode: postcode }})}>To Search</button>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} />
          {/* <PropertyMap translations={translations} address={summaryData['CMOS_Address_Single_Line']} /> */}
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} uprnOrVoa={'uprn'} />
          <CMOSUPRN translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <IdenteqUPRN translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          {/* <ValuationOffice translations={translations} summaryData={summaryData} /> */}
          <CMOSFormatted translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} />
        </div>
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} uprnOrVoa={'voa'} />
          <CMOSVOA translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <IdenteqVOA translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          {/* <AddressBase translations={translations} summaryData={summaryData} /> */}
          <ValuationOffice translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <CMOSFormattedVOA translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} />
        </div>
      </div>
    <BatchRunModal modalIsOpen={bulkModel === 'batchrun'} closeModal={() => setBulkModel('')} postcode={postcode} />
    </GenericPageContainer>    
  )
})
