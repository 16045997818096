import React, { Component, useState } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import { Redirect, Link, withRouter, matchPath } from 'react-router-dom'
import Card from '../../components/card/card'
import Modal from 'react-modal'
import Select from 'react-select'

/**
 * @summary Helper function: determine if datetime string is in the past
 * @param {string} datetime The datetime string to check
 * @returns {boolean} true if `datetime` is in future, else false.
 */
function isInPast(datetime) {
    if (!datetime) return false

    const inputDate = new Date(datetime.replace(" ", "T")); // Convert to ISO format
    const now = new Date();

    return now > inputDate
}

export default withRouter(class CompanyPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            match: matchPath(props.history.location.pathname, {
                path: '/admin/portal/companies/:company',
            }),
            company: {
                id: null,
                name: null,
                subdomain: null,
                frontends: [],
                users: [],
                vca_video_url: null,
                login_video_url: null,
                vot_cot_summary_video_url: null,
                vot_cot_search_video_url: null,
                vot_cot_workflow_video_url: null,
                vsa_summary_video_url: null,
                vsa_search_video_url: null,
                vsa_workflow_video_url: null,
                vsa_deed_purchase_webhook_url: null,

                obfuscated_names_vca: null,
                obfuscated_names_vca_nfi: null,
                vaca_confirmation_screen: null,
                vca_show_excluded: null,
                gsa_include_bulk_meter: null,
                gsa_bulk_buy_user: null,
                gsa_bulk_buy_userid: null,
                hide_non_client_users: null,
                twilio_s3_bucket: null,
                gsa_use_prop_type: null,
                show_n_a_in_table: null,

                osa_summary_video_url: null,
                osa_search_video_url: null,
                osa_workflow_video_url: null,
                osa_deed_purchase_webhook_url: null,
                products: [],

                idle_timeout: 10,

                baa_select_match_webhook_url: null,
                baa_select_match_webhook_key: null,

                ppv_deed_purchase_webhook_url: null,
                ppv_deed_purchase_webhook_key: null,
                exclusion_reasons_type: null,
                increase_s3_timeout_until: null,
            },
            error: false,
            form: {},
            databases: [],
            buckets: [],
            new_user: {
                name: '',
                email: '',
            },
            enableDeleteField: false,
            errors: {},
        }

        this.exclusionReasonsTypeOptions = [{value: 'Water', label: 'Water'}, {value: 'Energy', label: 'Energy'}]
    }

    formSubmit = () => {
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company, {
            name: this.state.company.name,
            subdomain: this.state.company.subdomain,
            input_bucket: this.state.company.input_bucket,
            output_bucket: this.state.company.output_bucket,
            vca_video_url: this.state.company.vca_video_url,
            login_video_url: this.state.company.login_video_url,
            vot_cot_summary_video_url: this.state.company.vot_cot_summary_video_url,
            vot_cot_search_video_url: this.state.company.vot_cot_search_video_url,
            vot_cot_workflow_video_url: this.state.company.vot_cot_workflow_video_url,

            vsa_summary_video_url: this.state.company.vsa_summary_video_url,
            vsa_search_video_url: this.state.company.vsa_search_video_url,
            vsa_workflow_video_url: this.state.company.vsa_workflow_video_url,
            vsa_deed_s3_bucket: this.state.company.vsa_deed_s3_bucket,
            ppv_deed_s3_bucket: this.state.company.ppv_deed_s3_bucket,
            vsa_deed_purchase_webhook_url: this.state.company.vsa_deed_purchase_webhook_url,

            twilio_s3_bucket: this.state.company.twilio_s3_bucket ?? '',

            osa_summary_video_url: this.state.company.osa_summary_video_url,
            osa_search_video_url: this.state.company.osa_search_video_url,
            osa_workflow_video_url: this.state.company.osa_workflow_video_url,
            osa_deed_s3_bucket: this.state.company.osa_deed_s3_bucket,
            osa_deed_purchase_webhook_url: this.state.company.osa_deed_purchase_webhook_url,

            obfuscated_names_vca: this.state.company.obfuscated_names_vca,
            obfuscated_names_vca_nfi: this.state.company.obfuscated_names_vca_nfi,
            vaca_confirmation_screen: this.state.company.vaca_confirmation_screen,
            vca_show_excluded: this.state.company.vca_show_excluded,
            gsa_include_bulk_meter: this.state.company.gsa_include_bulk_meter,
            gsa_bulk_buy_user: this.state.company.gsa_bulk_buy_user,
            gsa_bulk_buy_userid: this.state.company.gsa_bulk_buy_userid,
            hide_non_client_users: this.state.company.hide_non_client_users,
            gsa_use_prop_type: this.state.company.gsa_use_prop_type,
            show_n_a_in_table: this.state.company.show_n_a_in_table,

            cha_workflow_video_url: this.state.company.cha_workflow_video_url,
            cha_search_video_url: this.state.company.cha_search_video_url,
            cha_summary_video_url: this.state.company.cha_summary_video_url,
            vacant_workflow_video_url: this.state.company.vacant_workflow_video_url,
            vacant_search_video_url: this.state.company.vacant_search_video_url,
            vacant_summary_video_url: this.state.company.vacant_summary_video_url,

            branding_configuration_id: this.state.company.branding_configuration_id,

            idle_timeout: this.state.company.idle_timeout,

            baa_select_match_webhook_url: this.state.company.baa_select_match_webhook_url,
            baa_select_match_webhook_key: this.state.company.baa_select_match_webhook_key,

            ppv_deed_purchase_webhook_url: this.state.company.ppv_deed_purchase_webhook_url,
            ppv_deed_purchase_webhook_key: this.state.company.ppv_deed_purchase_webhook_key,

            azure_tenant_id: this.state.company.azure_tenant_id,
            twilio_phone_number: this.state.company.twilio_phone_number,
            exclusion_reasons_type: this.state.company.exclusion_reasons_type,
            increase_s3_timeout_until: this.state.company.increase_s3_timeout_until,
        }).catch(e => {
            console.error(e);
        })
    }

    updateCompany = (args = {}) => {
        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company).then(e => {
            this.setState({
                company: {
                    ...this.state.company,
                    id: e.data.id,
                    name: e.data.name,
                    subdomain: e.data.subdomain,
                    users: e.data.users,
                    frontends: e.data.frontends,
                    vca_video_url: e.data.vca_video_url ?? '',
                    login_video_url: e.data.login_video_url ?? '',

                    vot_cot_summary_video_url: e.data.vot_cot_summary_video_url ?? '',
                    vot_cot_search_video_url: e.data.vot_cot_search_video_url ?? '',
                    vot_cot_workflow_video_url: e.data.vot_cot_workflow_video_url ?? '',

                    vsa_summary_video_url: e.data.vsa_summary_video_url ?? '',
                    vsa_search_video_url: e.data.vsa_search_video_url ?? '',
                    vsa_workflow_video_url: e.data.vsa_workflow_video_url ?? '',
                    vsa_deed_s3_bucket: e.data.vsa_deed_s3_bucket ?? '',
                    ppv_deed_s3_bucket: e.data.ppv_deed_s3_bucket ?? '',
                    vsa_deed_purchase_webhook_url: e.data.vsa_deed_purchase_webhook_url ?? '',

                    twilio_s3_bucket: e.data.twilio_s3_bucket ?? '',

                    osa_summary_video_url: e.data.osa_summary_video_url ?? '',
                    osa_search_video_url: e.data.osa_search_video_url ?? '',
                    osa_workflow_video_url: e.data.osa_workflow_video_url ?? '',
                    osa_deed_s3_bucket: e.data.osa_deed_s3_bucket ?? '',
                    osa_deed_purchase_webhook_url: e.data.osa_deed_purchase_webhook_url ?? '',

                    vacant_summary_video_url: e.data.vacant_summary_video_url ?? '',
                    vacant_search_video_url: e.data.vacant_search_video_url ?? '',
                    vacant_workflow_video_url: e.data.vacant_workflow_video_url ?? '',

                    cha_summary_video_url: e.data.cha_summary_video_url ?? '',
                    cha_search_video_url: e.data.cha_search_video_url ?? '',
                    cha_workflow_video_url: e.data.cha_workflow_video_url ?? '',

                    obfuscated_names_vca: e.data.obfuscated_names_vca ?? null,
                    obfuscated_names_vca_nfi: e.data.obfuscated_names_vca_nfi ?? null,
                    vaca_confirmation_screen: e.data.vaca_confirmation_screen ?? null,
                    vca_show_excluded: e.data.vca_show_excluded ?? null,
                    gsa_include_bulk_meter: e.data.gsa_include_bulk_meter ?? null,
                    gsa_bulk_buy_user: e.data.gsa_bulk_buy_user ?? null,
                    gsa_bulk_buy_userid: e.data.gsa_bulk_buy_userid ?? null,
                    hide_non_client_users: e.data.hide_non_client_users ?? null,
                    gsa_use_prop_type: e.data.gsa_use_prop_type ?? null,
                    show_n_a_in_table: e.data.show_n_a_in_table ?? null,

                    branding_configuration_id: e.data.branding_configuration_id ?? null,

                    idle_timeout: e.data.idle_timeout || 10,

                    baa_select_match_webhook_url: e.data.baa_select_match_webhook_url ?? '',
                    baa_select_match_webhook_key: e.data.baa_select_match_webhook_key ?? '',

                    ppv_deed_purchase_webhook_url: e.data.ppv_deed_purchase_webhook_url ?? '',
                    ppv_deed_purchase_webhook_key: e.data.ppv_deed_purchase_webhook_key ?? '',

                    azure_tenant_id: e.data.azure_tenant_id ?? '',
                    twilio_phone_number: e.data.twilio_phone_number ?? '',

                    exclusion_reasons_type: e.data.exclusion_reasons_type ?? null,
                    increase_s3_timeout_until: e.data.increase_s3_timeout_until ?? null,

                    ...args,
                },
            }, () => { 
                    console.log(this.state.company) 
                    const timeoutDate = this.state.company.increase_s3_timeout_until
                    if (timeoutDate && !isInPast(timeoutDate)) {
                        this.setState({
                            s3_timeout_length: '60',
                        })
                    } else {
                        this.setState({
                            s3_timeout_length: '10',
                        })
                    }
                })
        }).catch(e => {
            this.setState({ error: true});
        });
    }

    updateCompanyDB = (args = {}) => {
        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/db').then(e => {
            this.setState({
                databases: {contact_validation: {
                        host: '',
                        port: '',
                        database: '',
                        username: '',
                        password: '',
                    },
                    test: {
                        host: '',
                        port: '',
                        database: '',
                        username: '',
                        password: '',
                    },
...e.data},
            });
        }).catch(e => {
            window.alert('ERROR', e)
            console.error(e);
        });

        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/buckets').then(e => {
            this.setState({
                buckets: {contact_validation: {
                        input_bucket: '',
                        output_bucket: '',
                    },
                    test: {
                        input_bucket: '',
                        output_bucket: '',
                    },
...e.data},
            });
        }).catch(e => {
            console.error(e);
        });
    }

    updateCompanyProducts = () => {
        request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/products').then(r => {
            let productList = r.data.products;
            request(true).get(endpoints.PRODUCTS).then(r => {
                productList = productList.filter(item => r.data.find(product => product.id == item.id).available)

                productList = productList.map(item => ({
                    ...item,
                    icon: r.data.find(product => product.id == item.id).icon,
                }))

                this.setState({ company: {
                    ...this.state.company,
                    products: productList,
                }});
            }).catch(e => {
                console.error(e);
            });
        }).catch(e => {
            console.error(e);
        });
    }

    componentDidMount() {
        this.updateCompany();
        this.updateCompanyDB();
        this.updateCompanyProducts();
    }
    
    handleIncreaseFileUploadTimeout = e => {
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/increase-s3-timeout').then(_ => {
            window.alert("File upload timeout set to 60 minutes");
            this.updateCompany();
        })
    }

    handleResetFileUploadTimeout = e => {
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/reset-s3-timeout').then(_ => {
            window.alert("File upload timeout reset to 10 minutes");
            this.updateCompany();
        })
    }

    updateFormValue = e => {
        console.log(e.target.name, e.target.value, this.state.company[e.target.name])
        this.setState({
            company: {
                ...this.state.company,
                [e.target.name]: e.target.value ? e.target.value : null,
            },
        })
    }

    updateProductSettings = e => {
        this.setState({
            company: {
                ...this.state.company,
                [e.target.name]: e.target.checked ? 1 : 0,
            },
        })
    }

    updateDatabaseValue = e => {
        this.setState({
            database: {
                ...this.state.database,
                [e.target.name]: e.target.value ? e.target.value : undefined,
            },
        })
    }

    updateProductChecked = e => {
        const products = [].concat(this.state.company.products);
        products.find(product => product.id == e.target.name).enabled = e.target.checked

        this.setState({ company: {
            ...this.state.company,
            products,
        }});
    }

    databaseFormSubmit = item => {
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/db', {
            product: item,
            database: this.state.databases[item],
        }).then(e => {
            window.alert(e.statusText)
            // console.log(e);
        }).catch(e => {
            window.alert(e.statusText)
            console.error(e);
        });
    }

    bucketFormSubmit = item => {
        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/buckets', {
            product: item,
            bucket: this.state.buckets[item],
        }).then(e => {
            // console.log(e);
        }).catch(e => {
            console.error(e);
        });
    }

    productFormSubmit = () => {
        const data = this.state.company.products.filter(item => item.enabled).map(item => item.id);

        request(true).post(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company + '/products', { products: data }).then(r => {
            console.log(r);
        }).catch(e => {
            console.error(e);
        })
    }

    updateNewUserFormValue = e => {
        this.setState({
            new_user: {
                ...this.state.new_user,
                [e.target.name]: e.target.value ? e.target.value : undefined,
            },
        })
    }

    newUserFormSubmit = () => {
        request(true).post(endpoints.ADMIN_COMPANIES_USER, {
            ...this.state.new_user,
            company_id: this.state.company.id,
        }).then(r => {
            this.updateCompany();
            this.setState({
                new_user: { name: '', email: '' },
            });
            this.props.history.push(`/admin/portal/users/${r.data.user_id}`);
        }).catch(r => {
            if (r.response.data.error == 'User email already in use') {
                window.alert('User email already in use');
                this.setState({
                    new_user: { name: '', email: '' },
                });
            } else {
                this.setState({
                    errors: r.response.data.errors,
                });
            }
        });
    }

    deleteFrontend = id => {
        const url = endpoints.ADMIN_COMPANIES_COMPANY_FRONTEND + '/' + id;
        request(true).delete(url).then(r => {
            this.updateCompany();
        }).catch(r => {
            // console.log(r.response);
        });
    }

    createFrontend = () => {
        const url = endpoints.ADMIN_COMPANIES_COMPANY_FRONTEND;
        // console.log(this.state.company);
        request(true).post(url, {
            company_id: this.state.company.id,
            frontend_url: this.state.company.new_frontend,
        }).then(r => {
            this.updateCompany({ new_frontend: '', errors: {} });
        }).catch(r => {
            // console.log(r.response);
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        })
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        })
    }

    deleteCompany = () => {
        if (this.state.enableDeleteField) {
            request(true).delete(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.match.params.company).then(r => {
                this.props.history.push('admin/portal/companies');
            }).catch(r => {
                console.error(r);
            });
        } else {
            this.setState({
                enableDeleteField: true,
            });
        }
    }

    render() {
        return this.state.error
            ? <Redirect to="/admin/portal/companies"/>
        : <div className="grid grid-gap-20" id="company-page">
                <Navigation />
                <Container>
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>{ this.state.company.name }</p>
                    </div>
                </Container>
                <Container>
                    { this.state.company.id !== null
                        ? <>
                            <Card>
                                <div className="grid grid-gap-15">
                                    <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-info-square" /> Basic Information</p>
                                    </div>
                                    <div className="grid grid-columns-2 grid-gap-15">
                                        <div className="form-row">
                                            <label htmlFor="name">Company Name:</label>
                                            <input onChange={this.updateFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.company.name} required/>
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="subdomain">Company Subdomain:</label>
                                            <input onChange={this.updateFormValue} name="subdomain" placeholder="Subdomain" className="input" type="text" value={this.state.company.subdomain} required/>
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="branding_configuration_id">Branding Configuration ID:</label>
                                            <input onChange={this.updateFormValue} name="branding_configuration_id" placeholder="Brand ID" className="input" type="text" value={this.state.company.branding_configuration_id} required/>
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="idle_timeout">Idle Timeout (Minutes):</label>
                                            <input onChange={this.updateFormValue} name="idle_timeout" placeholder="Idle Timeout" className="input" type="number" min={1} value={this.state.company.idle_timeout} required/>
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="azure_tenant_id">Azure Tenant ID</label>
                                            <input onChange={this.updateFormValue} name="azure_tenant_id" placeholder="Azure Tenant ID" className="input" type="text" value={this.state.company.azure_tenant_id} required/>
                                        </div>
                                        <div className="form-row">
                                            <p style={{marginBottom: 10, marginTop: 0}}>File Upload Timeout: {this.state.s3_timeout_length} mins {this.state.s3_timeout_length === "60" ? `(will reset to 10 mins after ${this.state.company.increase_s3_timeout_until})` : ''}</p>
                                            <div className="grid grid-columns-2 grid-gap-15">
                                                <button className="button background-primary colour-white" onClick={this.handleIncreaseFileUploadTimeout}>Increase File Upload Timeout</button>
                                                <button className="button background-red colour-white" onClick={this.handleResetFileUploadTimeout}>Reset File Upload Timeout</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-box" /> Company Specific Buckets</p>
                                    </div>
                                    <div className="grid grid-columns-2 grid-gap-15">
                                        { this.state.company.products.find(product => product.product_key == 'void_sales_alerts' && product.enabled)
                                            ? <>
                                                <div className="form-row">
                                                    <label htmlFor="vsa_deed_s3_bucket">VSA Deed Bucket Name</label>
                                                    <input onChange={this.updateFormValue} name="vsa_deed_s3_bucket" placeholder="VSA Deed Bucket Name" className="input" type="text" value={this.state.company.vsa_deed_s3_bucket}/>
                                                </div>
                                              </>
                                            : <></>}

                                        { this.state.company.products.find(product => product.product_key == 'proprietor_validation' && product.enabled)
                                            ? <>
                                                <div className="form-row">
                                                    <label htmlFor="ppv_deed_s3_bucket">PPV Deed Bucket Name</label>
                                                    <input onChange={this.updateFormValue} name="ppv_deed_s3_bucket" placeholder="PPV Deed Bucket Name" className="input" type="text" value={this.state.company.ppv_deed_s3_bucket}/>
                                                </div>
                                              </>
                                            : <></>}

                                        { this.state.company.products.find(product => product.product_key == 'occupied_sale_alert' && product.enabled)
                                            ? <>
                                                <div className="form-row">
                                                    <label htmlFor="osa_deed_s3_bucket">OSA Deed Bucket Name</label>
                                                    <input onChange={this.updateFormValue} name="osa_deed_s3_bucket" placeholder="OSA Deed Bucket Name" className="input" type="text" value={this.state.company.osa_deed_s3_bucket}/>
                                                </div>
                                              </>
                                            : <></>}
                                    </div>
                                    <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-box" /> Twilio Configuration</p>
                                    </div>
                                    <div className="grid grid-columns-2 grid-gap-15">
                                        <div className="form-row">
                                            <label htmlFor="twilio_phone_number">Twilio Phone Number</label>
                                            <input onChange={this.updateFormValue} name="twilio_phone_number" placeholder="Twilio Phone Number" className="input" type="text" value={this.state.company.twilio_phone_number} />
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="twilio_s3_bucket">Twilio Bucket Name</label>
                                            <input onChange={this.updateFormValue} name="twilio_s3_bucket" placeholder="Twilio Bucket Name" className="input" type="text" value={this.state.company.twilio_s3_bucket}/>
                                        </div>
                                    </div>
                                    {/* <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-link"></i> Company Specific Webhooks</p>
                                    </div>
                                    <div className="grid grid-columns-2 grid-gap-15">
                                        <div className="form-row">
                                            <label htmlFor="vsa_deed_purchase_webhook_url">VSA Deed Purchased Webhook URL</label>
                                            <input onChange={this.updateFormValue} name="vsa_deed_purchase_webhook_url" placeholder="VSA Deed Purchased Webhook URL" className="input" type="text" value={this.state.company.vsa_deed_purchase_webhook_url}/>
                                        </div>
                                        <div className="form-row">
                                            <label htmlFor="osa_deed_purchase_webhook_url">OSA Deed Purchased Webhook URL</label>
                                            <input onChange={this.updateFormValue} name="osa_deed_purchase_webhook_url" placeholder="OSA Deed Purchased Webhook URL" className="input" type="text" value={this.state.company.osa_deed_purchase_webhook_url}/>
                                        </div>
                                    </div> */}

                                    <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-link" /> Webhooks</p>
                                    </div>
                                    <div className="grid grid-columns-2 grid-gap-15">

                                        { this.state.company.products.find(product => product.product_key == 'business_address_alert' && product.enabled)
                                            ? <>
                                                    <div className="form-row">
                                                        <label htmlFor="baa_select_match_webhook_url">BAA Select Match URL:</label>
                                                        <input onChange={this.updateFormValue} name="baa_select_match_webhook_url" placeholder="BAA Select Match URL" className="input" type="text" value={this.state.company.baa_select_match_webhook_url} required/>
                                                    </div>
                                                    <div className="form-row">
                                                        <label htmlFor="baa_select_match_webhook_key">BAA Select Match Key:</label>
                                                        <input onChange={this.updateFormValue} name="baa_select_match_webhook_key" placeholder="BAA Select Match Key" className="input" type="text" value={this.state.company.baa_select_match_webhook_key} required/>
                                                    </div>

                                              </>
                                            : <></>}
                                        { this.state.company.products.find(product => product.product_key == 'proprietor_validation' && product.enabled)
                                            ? <>
                                                    <div className="form-row">
                                                        <label htmlFor="ppv_deed_purchase_webhook_url">PPV Deed Purchase URL:</label>
                                                        <input onChange={this.updateFormValue} name="ppv_deed_purchase_webhook_url" placeholder="PPV Deed Purchase URL" className="input" type="text" value={this.state.company.ppv_deed_purchase_webhook_url} required/>
                                                    </div>
                                                    <div className="form-row">
                                                        <label htmlFor="ppv_deed_purchase_webhook_key">PPV Deed Purchase Key:</label>
                                                        <input onChange={this.updateFormValue} name="ppv_deed_purchase_webhook_key" placeholder="PPV Deed Purchase Key" className="input" type="text" value={this.state.company.ppv_deed_purchase_webhook_key} required/>
                                                    </div>

                                              </>
                                            : <></>}

                                    </div>
                                    <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-video" /> Platform Videos</p>
                                    </div>
                                    <div className="grid grid-columns-2 grid-gap-15">
                                        <div className="form-row">
                                            <label htmlFor="login_video_url">Login Video URL:</label>
                                            <input onChange={this.updateFormValue} name="login_video_url" placeholder="Login Video URL" className="input" type="text" value={this.state.company.login_video_url} required/>
                                        </div>
                                    </div>

                                    { this.state.company.products.length && this.state.company.products.find(product => product.product_key == 'void_cot_alerts' && product.enabled)
                                        ? <>
                                            <div className="flex middle">
                                                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-video" /> VCA Videos</p>
                                            </div>
                                            <div className="grid grid-columns-2 grid-gap-15">
                                                {/* VCA */}
                                                <div className="form-row">
                                                    <label htmlFor="vca_video_url">VCA Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vca_video_url" placeholder="VCA Video URL" className="input" type="text" value={this.state.company.vca_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vot_cot_summary_video_url">Void COT Alerts Summary Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vot_cot_summary_video_url" placeholder="VCA Summary Video URL" className="input" type="text" value={this.state.company.vot_cot_summary_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vot_cot_search_video_url">Void COT Alerts Search Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vot_cot_search_video_url" placeholder="VCA Search Video URL" className="input" type="text" value={this.state.company.vot_cot_search_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vot_cot_workflow_video_url">Void COT Alerts Workflow Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vot_cot_workflow_video_url" placeholder="VCA Workflow Video URL" className="input" type="text" value={this.state.company.vot_cot_workflow_video_url} required/>
                                                </div>
                                            </div>
                                          </>
                                        : <></>}

                                    { this.state.company.products.length && this.state.company.products.find(product => product.product_key == 'void_sales_alerts' && product.enabled)
                                        ? <>
                                            <div className="flex middle">
                                                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-video" /> VSA Videos</p>
                                            </div>
                                            <div className="grid grid-columns-2 grid-gap-15">
                                                {/* VSA */}
                                                <div className="form-row">
                                                    <label htmlFor="vsa_summary_video_url">Void Sale Alerts Summary Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vsa_summary_video_url" placeholder="VSA Summary Video URL" className="input" type="text" value={this.state.company.vsa_summary_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vsa_search_video_url">Void Sale Alerts Search Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vsa_search_video_url" placeholder="VSA Search Video URL" className="input" type="text" value={this.state.company.vsa_search_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vsa_workflow_video_url">Void Sale Alerts Workflow Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vsa_workflow_video_url" placeholder="VSA Workflow Video URL" className="input" type="text" value={this.state.company.vsa_workflow_video_url} required/>
                                                </div>
                                            </div>
                                          </>
                                        : <></>}

                                    { this.state.company.products.length && this.state.company.products.find(product => product.product_key == 'occupied_sale_alert' && product.enabled)
                                        ? <>
                                            <div className="flex middle">
                                                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-video" /> OSA Videos</p>
                                            </div>
                                            <div className="grid grid-columns-2 grid-gap-15">
                                                {/* OSA */}
                                                <div className="form-row">
                                                    <label htmlFor="osa_summary_video_url">Occupied Sale Alerts Summary Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="osa_summary_video_url" placeholder="OSA Summary Video URL" className="input" type="text" value={this.state.company.osa_summary_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="osa_search_video_url">Occupied Sale Alerts Search Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="osa_search_video_url" placeholder="OSA Search Video URL" className="input" type="text" value={this.state.company.osa_search_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="osa_workflow_video_url">Occupied Sale Alerts Workflow Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="osa_workflow_video_url" placeholder="OSA Workflow Video URL" className="input" type="text" value={this.state.company.osa_workflow_video_url} required/>
                                                </div>
                                            </div>
                                          </>
                                        : <></>}

                                    { this.state.company.products.length && this.state.company.products.find(product => product.product_key == 'vacant_cot_alerts' && product.enabled)
                                        ? <>
                                            <div className="flex middle">
                                                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-video" /> Vacant COT Alerts Videos</p>
                                            </div>
                                            <div className="grid grid-columns-2 grid-gap-15">
                                                {/* Vacant */}
                                                <div className="form-row">
                                                    <label htmlFor="vacant_summary_video_url">Vacant COT Alerts Summary Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vacant_summary_video_url" placeholder="Vacant Summary Video URL" className="input" type="text" value={this.state.company.vacant_summary_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vacant_search_video_url">Vacant COT Alerts Search Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vacant_search_video_url" placeholder="Vacant Search Video URL" className="input" type="text" value={this.state.company.vacant_search_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vacant_workflow_video_url">Vacant COT Alerts Workflow Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="vacant_workflow_video_url" placeholder="Vacant Workflow Video URL" className="input" type="text" value={this.state.company.vacant_workflow_video_url} required/>
                                                </div>
                                            </div>
                                          </>
                                        : <></>}

                                    { this.state.company.products.length && this.state.company.products.find(product => product.product_key == 'companies_house_alerts' && product.enabled)
                                        ? <>
                                            <div className="flex middle">
                                                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}><i className="fad fa-video" /> CHA Videos</p>
                                            </div>
                                            <div className="grid grid-columns-2 grid-gap-15">
                                                {/* Vacant */}
                                                <div className="form-row">
                                                    <label htmlFor="vacant_summary_video_url">CHA Summary Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="cha_summary_video_url" placeholder="CHA Summary Video URL" className="input" type="text" value={this.state.company.cha_summary_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vacant_search_video_url">CHA Search Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="cha_search_video_url" placeholder="CHA Search Video URL" className="input" type="text" value={this.state.company.cha_search_video_url} required/>
                                                </div>
                                                <div className="form-row">
                                                    <label htmlFor="vacant_workflow_video_url">CHA Workflow Video URL:</label>
                                                    <input onChange={this.updateFormValue} name="cha_workflow_video_url" placeholder="CHA Workflow Video URL" className="input" type="text" value={this.state.company.cha_workflow_video_url} required/>
                                                </div>
                                            </div>
                                          </>
                                        : <></>}

                                    <div className="grid grid-columns-2 grid-gap-15">
                                        <button onClick={this.formSubmit} className="button background-primary colour-white">Update</button>
                                        <Link style={{ textAlign: 'center', textDecoration: 'none' }} class="button background-primary colour-white" to={ `/admin/portal/companies/${this.state.match.params.company}/translate`}>Translations</Link>
                                    </div>
                                </div>
                            </Card>

                            <div className="flex middle">
                                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Product Configuration</p>
                            </div>

                            <div className="grid grid-gap-15">

                                <Card>
                                    <div className="grid grid-columns-2 grid-gap-15" style={{marginBottom: 10}}>

                                        { this.state.company.products.filter(item => item.icon === 'fak fa-id-house-icon').length
                                            ? <div>
                                                <h3>COT Portal</h3>
                                                { this.state.company.products.filter(item => item.icon === 'fak fa-id-house-icon').map((item, index) => (
                                                        <div className="item" key={`product-${item.id}`}>
                                                            { item.name }: <input type="checkbox" name={ item.id } onChange={ this.updateProductChecked } checked={ item.enabled }/>
                                                        </div>
                                                    ))}
                                              </div>
                                        : null }

                                        { this.state.company.products.filter(item => item.icon === 'fak fa-id-business-icon').length
                                            ? <div>
                                                <h3>BD Portal</h3>
                                                { this.state.company.products.filter(item => item.icon === 'fak fa-id-business-icon').map((item, index) => (
                                                        <div className="item" key={`product-${item.id}`}>
                                                            { item.name }: <input type="checkbox" name={ item.id } onChange={ this.updateProductChecked } checked={ item.enabled }/>
                                                        </div>
                                                    ))}
                                              </div>
                                        : null }

                                    </div>
                                    <div class="grid grid-columns-2 grid-gap-15">
                                        <button onClick={ this.productFormSubmit } className="button background-primary colour-white">Update</button>
                                    </div>

                                    <div className="grid grid-columns-2 grid-gap-15" style={{marginBottom: 10}}>
                                        <div>
                                            <h3>Product Settings</h3>
                                            <div>
                                                <div>VCA Obfuscated Names: <input type="checkbox" name="obfuscated_names_vca" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.obfuscated_names_vca : false} /></div>
                                                <div>VCA Obfuscated Names NFI: <input type="checkbox" name="obfuscated_names_vca_nfi" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.obfuscated_names_vca_nfi : false} /></div>
                                                <div>VCA Show Excluded: <input type="checkbox" name="vca_show_excluded" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.vca_show_excluded : false} /></div>
                                                <div style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '10px' }}>
                                                    <label style={{ alignSelf: 'center' }}>Gentrack Confirmation Screen:</label>
                                                    <Select 
                                                        name='gentrack_confirmation_rocess' 
                                                        onChange={ (event) => this.setState({company: {...this.state.company, vaca_confirmation_screen: event.value}}) } 
                                                        placeholder={'Product'} 
                                                        value={
                                                            this.state.company?.vaca_confirmation_screen &&
                                                            Array.isArray(this.state.company.products)
                                                              ? (() => {
                                                                  const product = this.state.company.products.find(
                                                                    product => product.product_key === this.state.company.vaca_confirmation_screen
                                                                  );
                                                                  return product ? [{ value: product.product_key, label: product.name }] : null;
                                                                })()
                                                              : null
                                                          }
                                                        defaultValue={{ value: '', label: 'None' }}
                                                        options={
                                                            [{product_key: '', name: 'None'}].concat(
                                                                this.state.company.products.filter(product => product.product_key?.includes('vacant_cot_alerts'))
                                                            )
                                                            .map((product) => {return {value: product.product_key, label: product.name}})}
                                                    />
                                                </div>
                                                <div>GSA Include Bulk Meter in Allocate: <input type="checkbox" name="gsa_include_bulk_meter" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.gsa_include_bulk_meter : false} /></div>
                                                <div>GSA Enable Bulk Buy User: <input type="checkbox" name="gsa_bulk_buy_user" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.gsa_bulk_buy_user : false} /></div>
                                                <div>GSA Bulk Buy User ID: 
                                                    <input 
                                                        type="number" 
                                                        name="gsa_bulk_buy_userid" 
                                                        onChange={ (event) => this.setState({company: { ...this.state.company, gsa_bulk_buy_userid: event.target.value } })} 
                                                        value={this.state.company?.gsa_bulk_buy_userid} 
                                                    />
                                                </div>
                                                <div>Hide Non-Client Users: <input type="checkbox" name="hide_non_client_users" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.hide_non_client_users : false} /></div>
                                                <div>GSA Select Property Type on Accept: <input type="checkbox" name="gsa_use_prop_type" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.gsa_use_prop_type : false} /></div>
                                                <div style={{ display: 'grid', gridTemplateColumns: 'max-content 1fr', gap: '10px' }}>
                                                    <label style={{ alignSelf: 'center' }}>Exclusion Reasons Type:</label>
                                                    <Select 
                                                        name='exclusion_reasons_type' 
                                                        onChange={ (event) => this.setState({company: {...this.state.company, exclusion_reasons_type: event.value}}) } 
                                                        placeholder={'Reason Type'} 
                                                        value={ this.exclusionReasonsTypeOptions.find(option => option.value === this.state.company?.exclusion_reasons_type) } 
                                                        options={this.exclusionReasonsTypeOptions}
                                                    />
                                                </div>
                                                <div>Show N/A row in tables: <input type="checkbox" name="show_n_a_in_table" onChange={ this.updateProductSettings } checked={ this.state.company ? this.state.company.show_n_a_in_table : false} /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="grid grid-columns-2 grid-gap-15">
                                        <button onClick={this.formSubmit} className="button background-primary colour-white">Update</button>
                                    </div>
                                </Card>

                                <div className="flex middle">
                                    <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Product Databases</p>
                                </div>

                                <div className="grid grid-columns-3 grid-gap-15">
                                    { [
                                        this.state.company.products.find(product => product.product_key == 'address_alerts' && product.enabled) ? { value: 'address_alerts', name: 'Address Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'contact_validation' && product.enabled) ? { value: 'contact_validation', name: 'Contact Validation' } : null,
                                        this.state.company.products.find(product => product.product_key == 'companies_house_alerts' && product.enabled) ? { value: 'companies_house_alerts', name: 'Companies House Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'occupied_cot_alert' && product.enabled) ? { value: 'occupied_cot_alert', name: 'Occupied COT Alert', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'occupier_cot_alert' && product.enabled) ? { value: 'occupier_cot_alert', name: 'Occupier COT Alert', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'occupied_sale_alert' && product.enabled) ? { value: 'occupied_sale_alert', name: 'Occupied Sale Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'vacant_cot_alerts' && product.enabled) ? { value: 'vacant_cot_alerts', name: 'Vacant COT Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'occupier_telephone_validation' && product.enabled) ? { value: 'occupier_telephone_validation', name: 'Occupier Telephone Validation', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'void_sales_alerts' && product.enabled) ? { value: 'void_sales_alerts', name: 'Void Sale Alerts' } : null,
                                        this.state.company.products.find(product => product.product_key == 'void_cot_alerts' && product.enabled) ? { value: 'void_cot_alerts', name: 'Void COT Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'business_tele_append' && product.enabled) ? { value: 'business_tele_append', name: 'Business Tele Append', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'cot_validation' && product.enabled) ? { value: 'cot_validation', name: 'COT Validation', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'home_mover_alerts' && product.enabled) ? { value: 'home_mover_alerts', name: 'Home Mover Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'gap_sites_alerts' && product.enabled) ? { value: 'gap_sites_alerts', name: 'GAP Sites Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'gap_sites_alerts' && product.enabled) ? { value: 'gap_sites_alerts_abp', name: 'GAP Sites Alerts - ABP', disable_bucket: true } : null,
                                        this.state.company.products.find(product => product.product_key == 'real_time_validation' && product.enabled) ? { value: 'real_time_validation', name: 'Property Search', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'business_address_alert' && product.enabled) ? { value: 'business_address_alert', name: 'Business Address Alert', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'identeq_occupier_validation' && product.enabled) ? { value: 'identeq_occupier_validation', name: 'Identeq Occupier Validation', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'occupier_validation' && product.enabled) ? { value: 'occupier_validation', name: 'Occupier Validation', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'affordability_alerts' && product.enabled) ? { value: 'affordability_alerts', name: 'Affordability Alerts', disable_bucket: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'tariff_validation' && product.enabled) ? { value: 'tariff_validation', name: 'Tariff Validation', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'previous_occupier' && product.enabled) ? { value: 'previous_occupier', name: 'Previous Occupier', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'proprietor_validation' && product.enabled) ? { value: 'proprietor_validation', name: 'Proprietor Validation', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'wholesaler_gaps' && product.enabled) ? { value: 'wholesaler_gaps', name: 'Wholesaler GAPS', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'wholesaler_gaps' && product.enabled) ? { value: 'wholesaler_gaps_voa', name: 'Wholesaler VOA GAPS', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'wholesaler_gaps' && product.enabled) ? { value: 'vaca_gaps', name: 'VaCA GAPS', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'mixed_use' && product.enabled) ? { value: 'mixed_use', name: 'Mixed Use', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'mixed_use_manual_match' && product.enabled) ? { value: 'mixed_use_manual_match', name: 'Mixed Use - Manual Match', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'household_property' && product.enabled) ? { value: 'household_property', name: 'Household Property', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'energy_gone_away_alerts' && product.enabled) ? { value: 'energy_gone_away_alerts', name: 'Energy Gone Away Alerts', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'non_household_gaps' && product.enabled) ? { value: 'non_household_gaps', name: 'Non-Household GAPS', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'non_household_gaps' && product.enabled) ? { value: 'non_household_gaps_voa', name: 'Non-Household VOA GAPS', dispatchEvent: false } : null,
                                        this.state.company.products.find(product => product.product_key == 'ltap_analysis' && product.enabled) ? { value: 'ltap_analysis', name: 'LTAP Analysis', dispatchEvent:false } : null,
                                        this.state.company.products.find(product => product.product_key == 'vacant_cot_alerts_uat' && product.enabled) ? { value: 'vacant_cot_alerts_uat', name: 'Vacant COT Alerts - UAT', dispatchEvent:false } : null,
                                    ].map((item, index) => {
                                        if (!item) { return null }

                                        const updateDatabaseState = event => {
                                            this.setState({ databases: {
                                                ...this.state.databases,
                                                [item.value]: {
                                                    ...this.state.databases[item.value],
                                                    [event.target.name]: event.target.value,
                                                },
                                            }}, () => {
                                                // console.log(this.state.databases);
                                            })
                                        }

                                        const updateBucketState = event => {
                                            this.setState({ buckets: {
                                                ...this.state.buckets,
                                                [item.value]: {
                                                    ...this.state.buckets[item.value],
                                                    [event.target.name]: event.target.value,
                                                },
                                            }}, () => {
                                                // console.log(this.state.buckets);
                                            })
                                        }

                                        return (
                                            <Card key={ `product-${item.value}` } className="slim">
                                                <div className="title">
                                                    { item.name }
                                                </div>
                                                <div className="grid grid-columns-1 grid-gap-15">
                                                    <div className="grid grid-gap-15">
                                                        <div className="form-row">
                                                            <label htmlFor="host">Database Host:</label>
                                                            <input onChange={updateDatabaseState} name="host" placeholder="Host" className="input slim" type="password" value={(this.state.databases[item.value] ?? {}).host} required/>
                                                        </div>
                                                        <div className="form-row">
                                                            <label htmlFor="port">Database Port:</label>
                                                            <input onChange={updateDatabaseState} name="port" placeholder="Port" className="input slim" type="number" value={(this.state.databases[item.value] ?? {}).port} required/>
                                                        </div>
                                                        <div className="form-row">
                                                            <label htmlFor="database">Database Name:</label>
                                                            <input onChange={updateDatabaseState} name="database" placeholder="Database" className="input slim" type="text" value={(this.state.databases[item.value] ?? {}).database} required/>
                                                        </div>
                                                        <div className="form-row">
                                                            <label htmlFor="username">Database User:</label>
                                                            <input onChange={updateDatabaseState} name="username" placeholder="User" className="input slim" type="text" value={(this.state.databases[item.value] ?? {}).username} required/>
                                                        </div>
                                                        <div className="form-row">
                                                            <label htmlFor="password">Database Password:</label>
                                                            <input onChange={updateDatabaseState} name="password" placeholder="Password" className="input slim" type="password" value={(this.state.databases[item.value] ?? {}).password} required/>
                                                        </div>
                                                        <button onClick={ () => this.databaseFormSubmit(item.value) } className="button background-primary colour-white">Update</button>
                                                    </div>
                                                    { item.disable_bucket !== true
                                                        ? <div className="grid grid-gap-15" style={{ height: 'fit-content' }}>
                                                            <div className="form-row">
                                                                <label htmlFor="input_bucket">Input Bucket:</label>
                                                                <input onChange={updateBucketState} name="input_bucket" placeholder="Input Bucket" className="input slim" type="text" value={(this.state.buckets[item.value] ?? {}).input_bucket} required/>
                                                            </div>
                                                            <div className="form-row">
                                                                <label htmlFor="output_bucket">Output Bucket:</label>
                                                                <input onChange={updateBucketState} name="output_bucket" placeholder="Output Bucket" className="input slim" type="text" value={(this.state.buckets[item.value] ?? {}).output_bucket} required/>
                                                            </div>
                                                            <button onClick={ () => this.bucketFormSubmit(item.value) } className="button background-primary colour-white">Update</button>
                                                          </div>
                                                    : '' }
                                                </div>
                                            </Card>
                                        )
                                    }) }
                                </div>
                            </div>

                            <div className="grid grid-columns-2 grid-gap-15 margin-top-25">

                            <div>
                                <div className="flex middle">
                                    <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Frontend URLs</p>
                                </div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>URL</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.company.frontends.map(subdomain => (
                                                    <tr key={ subdomain.id }>
                                                        <td>{ subdomain.frontend_url }</td>
                                                        <td className="actions"><span onClick={ () => this.deleteFrontend(subdomain.id) }><i className="fas fa-minus-circle"/></span></td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td>
                                                    <input onChange={this.updateFormValue} name="new_frontend" placeholder="Frontend URL" className="input" type="text" value={this.state.company.new_frontend} required/>
                                                </td>
                                                <td className="actions">
                                                    <span onClick={ this.createFrontend }><i className="fas fa-plus-circle" /></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                            </div>

                                <div>
                                    <div className="flex middle">
                                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Company Users</p>
                                    </div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Email Address</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { this.state.company.users.map(user => (
                                                    <tr key={ user.id }>
                                                        <td>{ user.name }</td>
                                                        <td className="actions"><Link to={ '/admin/portal/users/' + user.id }><i className="fa fa-search" aria-hidden="true" /></Link></td>
                                                    </tr>
                                                ))}
                                            <tr>
                                                <td colSpan="2">
                                                    <button onClick={this.openModal} className="button background-primary colour-white">Add A User</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className={ this.state.enableDeleteField ? 'delete enabled' : 'delete disabled'} style={{ textAlign: 'center', marginTop: '40px' }}>
                                <button onClick={this.deleteCompany} className="button background-red colour-white">
                                    <span style={{ marginRight: '20px' }}>{ this.state.enableDeleteField ? 'Are you sure?' : 'Delete This Company' }</span>
                                    <i className="fa fa-unlock" aria-hidden="true" />
                                    <i className="fa fa-lock" aria-hidden="true" />
                                </button>
                            </div>
                          </>
                    : <></>}
                </Container>
                <Modal
                  style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                  className="card"
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  contentLabel="Add A User"
                  appElement={document.getElementById('company-page')}
                >
                    <div className="grid grid-gap-15">
                            <div className="form-row">
                                <label htmlFor="name">Name:</label>
                                <input onChange={this.updateNewUserFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.new_user.name} required/>
                                { this.state.errors.name ? this.state.errors.name.map((value, index) => (
                                        <p key={index}>
                                            { value }
                                        </p>
                                    )) : <></>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="email">Email:</label>
                                <input onChange={this.updateNewUserFormValue} name="email" placeholder="Email" className="input" type="text" value={this.state.new_user.email} required/>
                                { this.state.errors.email ? this.state.errors.email.map((value, index) => (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )) : <></>}
                            </div>
                            <button onClick={this.newUserFormSubmit} className="button background-primary colour-white">Create</button>
                            <p className="text-center">{ this.state.message }</p>
                    </div>
                </Modal>
                <Footer />
          </div>;
    }
})
