import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import session from '../../../stores/session'
import endpoints from '../../../helpers/endpoints'
import ReactModal from 'react-modal'
import { Summary, CMOSFormatted } from './shared'
import { AddressBase, CouncilTax, CMOSLive, ValuationOffice } from '../HouseholdProperty/shared'
import { useTranslations } from '../../../helpers/hooks'

export default view(function BusinessAddressAlertWorkflow() {
  const [summaryData, setSummaryData] = useState({})
  const [selectedMatches, setSelectedMatches] = useState({abp: null, voa: null, ct: null})
  const [dataIssue, setDataIssue] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  const translations = useTranslations('business_address_alert')
  const prefix = "BUSINESS_ADDRESS_ALERTS"

  useEffect(() => {
    setSelectedMatches({abp: {}, voa: {}, ct: {}})
  }, [summaryData.SPID])
  return (
    <GenericPageContainer
      containerWidth={"92vw"}
      title={`${translations['module_title'] || ''} - Workflow`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/business-address-alert">
              To Summary
          </Link>
          <Link className="navigate" to="/portal/business-address-alert/search">
              To Search
          </Link>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10, gridTemplateColumns: '2fr 1fr'}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} prefix={prefix} />
          <PropertyMap translations={translations} address={summaryData['CMOS_Address_Single_Line']} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} />
          <ValuationOffice translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} />
          <CouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} prefix={prefix} />
          <CMOSFormatted translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} workflow={true} selectedMatches={selectedMatches} prefix={prefix} setConfirmModal={setConfirmModal} />
        </div>
        <div>
          {/* <div style={{display: 'flex', gap: '10px', float: 'right'}}>
            <button onClick={() => {setConfirmModal(true); setDataIssue('')}} className='button compact smaller-text background-primary colour-white'>Confirm</button>
          </div> */}
          <ReactModal 
          isOpen={confirmModal} 
          onRequestClose={() => setConfirmModal(false)}
          className="card bulk-allocate"
          contentLabel="Wholesaler GAPs No Match"
          style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
              <h3>{dataIssue ? dataIssue : "Confirm"}</h3>
              {
                dataIssue ? 
                  <p>Please confirm you wish to report this SPID as {dataIssue}.</p>
                :
                  <p>Please confirm you are finished working on this SPID.</p>
              }
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <button className="button compact smaller-text cancel" onClick={ () => setConfirmModal(false) }>Cancel</button>
                <button className="button compact smaller-text background-primary colour-white" onClick={() => {
                  request(true).post(endpoints.BUSINESS_ADDRESS_ALERTS_SELECT_MATCH, {
                    spid: summaryData.SPID,
                    data_issue: dataIssue ?? ''
                  }).then(r => {
                    setSummaryData({})
                    window.reloadWorkflow()
                    setConfirmModal(false); 
                  })
                }}
                >Confirm</button> 
              </div>
            </div>
          </ReactModal>
        </div>
      </div>
    </GenericPageContainer>    
  )
})
