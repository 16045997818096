import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './HouseholdProperty.scss';
import { useTranslations } from "../../../helpers/hooks";

export default view(function HouseholdPropertySummary() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Type");
  const [groupings, setGroupings] = useState([]);
  const [group, setGroup] = useState(undefined);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')
  const translations = useTranslations('business_address_alert')
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.HOUSEHOLD_PROPERTY_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);

  useEffect(() => {
    request(true)
      .get(endpoints.HOUSEHOLD_PROPERTY_GROUPINGS)
      .then(e => {
        setGroupings(e.data);
        setGroup(e.data.find(_ => _.default))
      })
  }, [])

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.HOUSEHOLD_PROPERTY_DATA, {
        params: {
          date: date,
          filter: filters,
          group: group?.target_column,
        },
        doesCancel: true,
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, group, date]);

  return [
    <GenericPageContainer
      title={`${translations['hp_module_title'] || ''} - Summary`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/household-property/search">
              To Search
          </Link>
          { (hasPermission('HOUSEHOLD_PROPERTY', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/household-property/workflow">
                To Workflow
            </Link>
          : null }
          {hasPermission('HOUSEHOLD_PROPERTY', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
          {hasPermission('HOUSEHOLD_PROPERTY', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload")}>
              Issue Input File
            </button>
          ) : null}
          <Link className="navigate" to="/portal/household-property/report">
            To Report
          </Link>
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='HP'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={group}
          setSelectectGroupBy={setGroup}
          groupingsEndpoint={endpoints.HOUSEHOLD_PROPERTY_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.HOUSEHOLD_PROPERTY_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('HOUSEHOLD_PROPERTY', 'BULK-BUY')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
              { (hasPermission('HOUSEHOLD_PROPERTY', 'ALLOCATE')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
              { (hasPermission('HOUSEHOLD_PROPERTY', 'UNALLOCATE')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate')}>
                      Unallocate
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.HOUSEHOLD_PROPERTY_FILES}
      uploadUrl={endpoints.HOUSEHOLD_PROPERTY_FILES}
    />,
    <BulkBuy 
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    buyButtonText="Create File"
    completeOnFirstStep={true} 
    workflowUsersEndpoint={endpoints.HOUSEHOLD_PROPERTY_WF_USERS} 
    bulkEndpoint={endpoints.HOUSEHOLD_PROPERTY_BULK_BASE} 
    modalIsOpen={bulkModel === 'purchase'} 
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
    <BulkAllocate workflowUsersEndpoint={endpoints.HOUSEHOLD_PROPERTY_WF_USERS} bulkEndpoint={endpoints.HOUSEHOLD_PROPERTY_BULK_BASE} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <BulkAllocate 
        workflowUsersEndpoint={endpoints.HOUSEHOLD_PROPERTY_WF_USERS} 
        bulkEndpoint={endpoints.HOUSEHOLD_PROPERTY_BULK_BASE} 
        unallocateEndpoint={endpoints.HOUSEHOLD_PROPERTY_UNALLOCATE_GET}
        modalIsOpen={bulkModel === 'unallocate'} 
        mode="unallocate"
        closeModal={() => setBulkModel('')} 
        filters={filters} 
        date={date} 
        reallocate={hasPermission('HOUSEHOLD_PROPERTY', 'ALLOCATE') && hasPermission('HOUSEHOLD_PROPERTY', 'UNALLOCATE')}
    />,
  ];
})
