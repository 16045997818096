import React, { useEffect, useCallback } from 'react'
import { useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import endpoints from '../../../helpers/endpoints';
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request';
import {
  CompanyInfo,
  GoogleEvidence,
  CompaniesHouseEvidence,
  YellEvidence,
  AddressDataEvidence,
  FSAEvidence,
  BTEvidence,
  TripAdvisorEvidence,
  DVSAEvidence,
  CQCEvidence, 
  JustEatEvidence, 
  One92Evidence, 
  FacebookEvidence,
  TitleOwnerRow
} from './shared'
import Select from 'react-select'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import session from '../../../stores/session';
import PropertyMap from '../../../components/property-map/property-map'
import Twilio from '../../../components/twilio/twilio';
import ReactModal from 'react-modal';
import { useTranslations } from '../../../helpers/hooks';

export default function IdenteqOccupierValidationSearch() {
  const [SPID, setSPID] = useState('')
  const [data, setData] = useState({});
  const [purchasable, setPurchasable] = useState(false)
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('O')
  const translations = useTranslations('occupier_validation')
  const [customerNumber, setCustomerNumber] = useState()
  const [twilioHeight, setTwilioHeight] = useState('500')
  const [moveinType, setMoveinType] = useState('null')
  const [exclusionReason, setExclusionReason] = useState('null')
  const [exclusionFeedback, setExclusionFeedback] = useState()
  const [purchaseType, setPurchaseType] = useState('null')
  const [purchaseModalOpen, setPurchaseModalOpen] = useState(false)

  useEffect(() => {
    const div = document.getElementById('ov-search');
    setTwilioHeight(div ? div.clientHeight : null);
    }, [])
  const getData = useCallback(() => {
    if (!SPID) return;
    setCustomerNumber(null);
    setLoading(true)
    setData([])
    setPurchasable(false)
    request(true).get(endpoints.IDENTEQ_OCCUPIED_VALIDATION_LOOKUP, {
      params: {
        SPID
      }
    }).then(e => {
      setLoading(false)
      setData(e.data)
      setPurchasable(e.data.Date_Bought === null)
      setPurchaseType(e.data.Purchase_Type || 'null')
      setMoveinType(e.data.Occupier_Purchase_Type || 'null')
      setExclusionReason(e.data.Occupier_Exclusion_Reason || 'null')
    }).catch(e => {
      setLoading(false)
      setData({})
      setPurchasable(false)
      window.alert('Could not find SPID.')
    })
  }, [SPID])
  useEffect(() => {
    request(true).post(`${endpoints.IDENTEQ_OCCUPIER_VALIDATION_UPDATE_STATUS}`, {
      customer: data['SPID'],
      status: data['status']
    }).catch(e => {
      console.log(e);
    })
  }, [data.status])
  const purchase = () => {
    request(true).post(endpoints.IDENTEQ_OCCUPIER_VALIDATION_PURCHASE, {
      spid: SPID,
      Occupier_Purchase_Type: moveinType
    }).then(e => {
      setPurchaseModalOpen(false)
    }).then(e => {
      setSPID('')
      getData()
      window.alert('Purchased data')
    }).catch(() => {
      console.log('Unable to purchase')
    })
  }

  const exclude = () => {
    request(true).post(endpoints.IDENTEQ_OCCUPIER_VALIDATION_EXCLUDE, {
      spid: SPID,
      Occupier_Exclusion_Reason: exclusionReason,
      feedback: exclusionFeedback
    }).then(e => {
      setExclusionReason('null')
    }).catch(() => {
      console.log('Unable to exclude')
    })
  }

  const updateStatus = (event) => {
    setData({
      ...data,
      status: event
    })
  }
  const updateCustomerNumber = (number) => {
    setCustomerNumber(number)
  }
  const updateCustomer = (customer) => {
    setData(customer)
  }
  return (
    <GenericPageContainer containerWidth={'97.5vw'} title={`${translations['iov_module_title'] || ''} - Search`}
    titleRight={<div>
        {
          loading ? <CircularProgress size={22} /> : null
        }
        <Link className="navigate" to="/portal/identeq-occupier-validation">
            To Overview
        </Link>
        { (hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/identeq-occupier-validation/workflow">
                To Workflow
            </Link>
        : null }
      </div>}>
      <div style={{display:'grid', gridTemplateColumns:'4fr 1fr', gridGap:'15px', gap:'15px'}}>
        <div id="ov-search">
          <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: 10, alignItems: 'center' }}>
              <GenericDataRow title={translations['spid']} contentColour={'var(--white)'}>
                <input disabled={loading} style={{ width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)' }} value={SPID} onChange={e => setSPID(e.target.value)} />
              </GenericDataRow>
              <button className="navigate" onClick={getData} style={{ marginLeft: 0 }}>
                Search
              </button>
              { (hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'PURCHASE') && purchasable) ?
                <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => {setPurchaseModalOpen(true); setMoveinType(_.target.value)}} value={moveinType}>
                  <option value={'null'}>Occupier Moved In</option>
                  <option value={'Client Occupier Moved In'}>Client Occupier Moved In</option>
                  <option value={'Identeq Occupier Moved In'}>Identeq Occupier Moved In</option>
                  <option value={'Other Occupier Moved In'}>Other Occupier Moved In</option>
                </select>
                :
                <button className="buy-button" disabled>
                  { 
                    purchasable === null ? "Occupier Moved In" :
                      purchaseType.includes("PURCHASE") ?
                        data.Bought_By ?
                          `Purchased on ${data.Date_Bought} by ${data.Bought_By}`
                          :
                          data.Date_Bought ?
                            `Purchased on ${data.Date_Bought}`
                            :
                            'Unavailable for Purchase'
                      :
                        data.Bought_By ?
                          `Excluded on ${data.Date_Bought} by ${data.Bought_By}`
                          :
                          data.Date_Bought ?
                            `Excluded on ${data.Date_Bought}`
                            :
                            'Unavailable for Purchase'
                  }
                </button>
              }
            </div>
            <CompanyInfo translations={translations} data={data} mode={mode} setMode={setMode} updateStatus={updateStatus} setCustomerNumber={setCustomerNumber} right={<>
              {(hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'PURCHASE') && purchasable) ?
                <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => setExclusionReason(_.target.value)} value={exclusionReason}>
                  <option value={'null'}>Excluded</option>
                  <option value={'No Answer'}>No Answer</option>
                  <option value={'Refusal to confirm details'}>Refusal to confirm details</option>
                  <option value={'No Telephone Number'}>No Telephone Number</option>
                  <option value={'Potentially Vacant'}>Potentially Vacant</option>
                  <option value={'Address DQI'}>Address DQI</option>
                  <option value={'Previous Occupier'}>Previous Occupier</option>
                  <option value={'Adjacent Occupier'}>Adjacent Occupier</option>
                  <option value={'Invalid Telephone Number'}>Invalid Telephone Number</option>
                  <option value={'Insufficient Information'}>Insufficient Information</option>
                  <option value={'Other'}>Other</option>
                </select>
                :
                  purchaseType.includes("PURCHASE") ? 
                    <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => {setPurchaseModalOpen(true); setMoveinType(_.target.value)}} value={moveinType}>
                      <option value={'null'}>Occupier Moved In</option>
                      <option value={'Client Occupier Moved In'}>Client Occupier Moved In</option>
                      <option value={'Identeq Occupier Moved In'}>Identeq Occupier Moved In</option>
                      <option value={'Other Occupier Moved In'}>Other Occupier Moved In</option>
                    </select>
                    :
                    <select style={{ height: 28, marginTop: 7, borderRadius: 5, backgroundColor: 'var(--secondary)', color: 'white', fontWeight: 'bold' }} onChange={_ => setExclusionReason(_.target.value)} value={exclusionReason}>
                      <option value={'null'}>Excluded</option>
                      <option value={'No Answer'}>No Answer</option>
                      <option value={'Refusal to confirm details'}>Refusal to confirm details</option>
                      <option value={'No Telephone Number'}>No Telephone Number</option>
                      <option value={'Potentially Vacant'}>Potentially Vacant</option>
                      <option value={'Address DQI'}>Address DQI</option>
                      <option value={'Previous Occupier'}>Previous Occupier</option>
                      <option value={'Adjacent Occupier'}>Adjacent Occupier</option>
                      <option value={'Invalid Telephone Number'}>Invalid Telephone Number</option>
                      <option value={'Insufficient Information'}>Insufficient Information</option>
                      <option value={'Other'}>Other</option>
                    </select>
              }
            </>} />
            <TitleOwnerRow translations={translations} data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
              <GoogleEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <CompaniesHouseEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <YellEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <AddressDataEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
              <FSAEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <BTEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <TripAdvisorEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <DVSAEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
              <CQCEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <JustEatEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <One92Evidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <FacebookEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
          </div>

          <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px', display: 'grid', minHeight: '300px'}}>
                <table className="table borders squish super-small-text extra-row-padding">
                  <thead>
                      <tr>
                          <th style={{textAlign: 'left'}}>
                              Days
                          </th>
                          <th>
                              Opening Times
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                        ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                            return (
                                <tr>
                                    <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                    <td style={{minWidth: "unset"}}>{ data?.[mode + "_Google_Opening_Hours"] ? JSON.parse(data?.[mode + "_Google_Opening_Hours"].replaceAll('\'', '"'))[day] : null }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
              </table>
              <div style={{height: 'auto'}}>
                  <PropertyMap address={ data ? data.SPID_Address : null } />
              </div>
          </div>
        </div>
        <Twilio module="identeq_occupier_validation" accountNumber={data ? SPID : null} customer={data} updateCustomer={updateCustomer} customerNumber={customerNumber} updateCustomerNumber={updateCustomerNumber} height={twilioHeight} surveyEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_SUBMIT_ANSWERS} />
      </div>
      <ReactModal
        className="card bulk-buy"
        isOpen={purchaseModalOpen}>
        <p>You have selected the following move in type</p>
        <p>{moveinType}</p>
        <div className='grid grid-columns-2 grid-gap-5'>
          <button className='button compact background-red colour-white' onClick={() => setMoveinType('null')}>Cancel</button>
          <button className='button compact background-primary colour-white' onClick={() => purchase()}>Confirm</button>
        </div>
      </ReactModal>
      <ReactModal
        className="card bulk-buy"
        isOpen={exclusionReason !== 'null'}>
        <p>You have selected the following exclusion reason:</p>
        <p><strong>{exclusionReason}</strong></p>
        <div style={{ display: 'grid', marginBottom: '1em' }}>
          <p>Additional Feedback:</p>
          <textarea onChange={(event) => setExclusionFeedback(event.target.value.slice(0, 500))} value={exclusionFeedback ?? ''} rows={5} style={{ resize: 'none' }}/>
          <div style={{ marginLeft: 'auto', color: 'gray', fontSize: 12 }}>
            {exclusionFeedback?.length ?? 0}/500
          </div>
        </div>
        <div className='grid grid-columns-2 grid-gap-5'>
          <button className='button compact background-red colour-white' onClick={() => setExclusionReason('null')}>Cancel</button>
          <button className='button compact background-primary colour-white' onClick={() => exclude()}>Confirm</button>
        </div>
      </ReactModal>
    </GenericPageContainer>
  )
}
