import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import request from "../../../helpers/request";
import hasPermission from "../../../helpers/permissions";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './MixedUse.scss'; 
import { useHistory } from 'react-router-dom';
import S3Modal from '../../../components/s3Modal/S3Modal';
import ReactModal from 'react-modal'
import { useTranslations } from "../../../helpers/hooks";

export default view(function MixedUseSummary() {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("OUTPUT");
  const [groupings, setGroupings] = useState([]);
  const [group, setGroup] = useState(undefined);
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [s3Modal, sets3Modal] = useState('');
  const [bulkModal, setBulkModal] = useState(false);
  const translations = useTranslations('mixed_use')
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    // request(true)
    //   .get(`${endpoints.MIXED_USE_DATES}`)
    //   .then((e) => {
    //     setDates(e.data.dates);
    //     if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
    //   });
    getData();
  }, []);

  useEffect(() => {
    request(true)
      .get(endpoints.MIXED_USE_GROUPINGS)
      .then(e => {
        setGroupings(e.data);
        setGroup(e.data.find(_ => _.default))
      })
  }, [])

  useEffect(() => {
    getData();
  }, [filters, group, date]);

  const getData = () => {
    // if (!date) return;
    setData(null)
    request(true)
      .get(`${endpoints.MIXED_USE_DATA}`, {
        params: {
          // date: date,
          filter: filters,
          group: group?.target_column,
        },
        doesCancel: true,
      })
      .then((e) => {
        setData(e.data);
      });
  }

  return [
    <GenericPageContainer
      title={`${translations['module_title'] || ''} - Summary`}
      titleRight={
        <div>
          <button className="navigate" onClick={() => sets3Modal('download')}>
            To Download
          </button>
          <Link className="navigate" to={'/portal/mixed-use/search'}>
            To Search
          </Link>
        </div>
      }
    >
      <div className='MU'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={group}
          setSelectectGroupBy={setGroup}
          groupingsEndpoint={`${endpoints.MIXED_USE_GROUPINGS}`}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
        />
        <GenericProductFilterContainer>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.MIXED_USE_FILTERS}
            inputFilters={["MIXED_USE_MATCH_DATE"]}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('MIXED_USE', 'BULK-BUY')) ?
                <button className="button bulk-buy-button compact" onClick={() => setBulkModal(true)}>
                  Create Download File
                </button>
              : '' }
          </div>
      </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <BulkBuy
        SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
        SentenceSecond={() => <></>}
        buyButtonText="Create File" completeOnFirstStep={true} bulkEndpoint={endpoints.MIXED_USE_SUMMARY_BULK} modalIsOpen={bulkModal} closeModal={() => setBulkModal(false)} filters={filters} />,
    <S3Modal showModal={s3Modal} closeModal={() => sets3Modal('')} modalMode={s3Modal ? 'download' : ''} listingUrl={endpoints.MIXED_USE_FILES + `?mode=${s3Modal.substring(s3Modal.indexOf('-') + 1)}`} uploadUrl={endpoints.MIXED_USE_FILES + `/${s3Modal.substring(s3Modal.indexOf('-') + 1)}`}/>
  ];
})
