import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions'
import { useHistory } from 'react-router-dom';
import { Summary } from './shared'
import { Link } from 'react-router-dom'
import { useTranslations } from '../../../helpers/hooks'

export default view(function MixedUseSearch() {
  const translations = useTranslations('mixed_use')
  const [bulkModel, setBulkModel] = useState('');

  return (
    <GenericPageContainer
      containerWidth="92vw"
      title={`${translations['module_title'] || ''} - Search`}
      titleRight={
        <div>
          <Link className="navigate" to={'/portal/mixed-use/'}>
            To Summary
          </Link>
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <Summary translations={translations} />
      </div>
    </GenericPageContainer>
  )
})
