import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions'
import { AddressTable, ClientTenants, MoveInOutTenants, Summary } from './shared'
import { useTranslations } from '../../../helpers/hooks'


export default view(function LTAPAnalysisSearch() {
    const [summaryData, setSummaryData] = useState({})
    const translations = useTranslations('ltap_analysis')
    const [status, setStatus] = useState('null')
    const [propRef, setPropRef] = useState()
    const [clientId, setClientId] = useState()

    const clearSummaryData = () => {
        setSummaryData({})
        setPropRef('')
        setClientId('')
    }

    return (
        <GenericPageContainer
            containerWidth="92vw"
            title={`${translations['module_title'] || ''} - Search`}
            titleIconClass={"fak fa-id-house-icon fa-2x colour-primary"}
            titleRight={
                <div style={{ marginBottom: '10px' }}>
                    <Link className="navigate" to="/portal/ltap-analysis">To Summary</Link>
                    { (hasPermission("LTAP_ANALYSIS", "WORKFLOW")) ?
                        <Link className="navigate" to="/portal/ltap-analysis/workflow">To Workflow</Link> : null
                    }
                </div>
            }
        >
            <div className="lta" style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                <div style={{ display: 'grid', gridTemplateColumns: "1.3fr 4fr", gap: 10, paddingBottom: "15px", borderBottom: "4px dashed #e8e8e8" }}>
                    <Summary 
                        translations={translations} 
                        summaryData={summaryData} 
                        setSummaryData={setSummaryData} 
                        status={status} 
                        setStatus={setStatus} 
                        clearSummaryData={clearSummaryData}
                        workflow={false}
                    />
                    <AddressTable 
                        translations={translations} 
                        summaryData={summaryData} 
                        setSummaryData={setSummaryData} 
                        propRef={propRef}
                        setPropRef={setPropRef}
                        clientId={clientId}
                        setClientId={setClientId}
                        workflow={false} 
                    />
                </div>

                <div style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10, paddingTop: 5 }}>
                    <ClientTenants translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} maxTenants={5} />
                </div>

                <div style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10 }}>
                    <MoveInOutTenants translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} maxTenants={5} mode='in' />
                </div>
                <div style={{ display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10 }}>
                    <MoveInOutTenants translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} maxTenants={5} mode='out' />
                </div>
            </div>
        </GenericPageContainer>
    )
})
