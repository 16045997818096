import { isAcceptedOrRejected } from "./utils";

export default function searchReducer(state, action) {
  switch (action.type) {
    case 'clear_screen': {
      return {
        ...state,
        loading: false,
        searchResults: [],

        installationNumber: '',
        uprn: '',
        subBuildingName: '',
        buildingName: '',
        buildingNumber: '',
        thoroughfare: '',
        dependantLocality: '',
        postTown: '',
        postcode: '',
      }
    }
    case 'set_installation_number': {
      return {
        ...state,
        installationNumber: action.payload,
      }
    }
    case 'set_uprn': {
      return {
        ...state,
        uprn: action.payload,
      }
    }
    case 'set_sub_building_name': {
      return {
        ...state,
        subBuildingName: action.payload,
      }
    }
    case 'set_building_name': {
      return {
        ...state,
        buildingName: action.payload,
      }
    }
    case 'set_building_number': {
      return {
        ...state,
        buildingNumber: action.payload,
      }
    }
    case 'set_thoroughfare': {
      return {
        ...state,
        thoroughfare: action.payload,
      }
    }
    case 'set_dependant_locality': {
      return {
        ...state,
        dependantLocality: action.payload,
      }
    }
    case 'set_post_town': {
      return {
        ...state,
        postTown: action.payload,
      }
    }
    case 'set_postcode': {
      return {
        ...state,
        postcode: action.payload,
      }
    }
    case 'set_search_results': {
      return {
        ...state,
        searchResults: action.payload,
      };
    }
    case 'update_page': {
      return {
        ...state,
        page: action.payload
      };
    }
    default: {
      console.log("Unknown action: " + action.type)
    }
  }
}
