import React, { useState, useEffect, useCallback, useReducer } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer.jsx'
import request from '../../../helpers/request.js'
import endpoints from '../../../helpers/endpoints.js'
import { view } from '@risingstack/react-easy-state'
import './GapSitesAlerts.scss';
import PropertyMap from '../../../components/property-map/property-map.jsx'
import workflowReducer from './GapSitesAlertsWorkflowReducer.js'

import { Link, withRouter, matchPath, useLocation } from 'react-router-dom'
import session from '../../../stores/session.js'
import hasPermission from '../../../helpers/permissions.js';
import { useTranslations } from '../../../helpers/hooks.js'
import ReactModal from 'react-modal'

export default view(
  withRouter(
    function GapSitesAlertsWorkflow() {
      const statuses = ["", "In Progress", "GAP Accepted"]

      const translations = useTranslations('gap_sites_alerts');

      const location = useLocation()
      const match = matchPath(location.pathname, {
        path: '/portal/gap-sites-alerts/workflow/:ABP_UPRN?'
      })

      const [detailsModalIsOpen, setDetailsModalIsOpen] = useState(false)

      const initialState = {
        uprn: '',
        accountNumber: '',
        abpAddressId: null,
        customer: null,
        workflow: [],
        workflowIndex: null,
        workflowLength: 0,
        matchedAddress: null,
        addressData: null,
        occupierDetails: {},
        loading: true,
        workflowCounts: [],
        workflowTab: "",
        showMap: false,
        propertyType: null,
        rejectionReason: null,
        gapStatus: null,
        notes: '',
      }

      const [state, dispatch] = useReducer(workflowReducer, initialState)

      const doUprnSearch = useCallback(() => {
        if (!state.uprn) return dispatch({ type: 'clear_screen' })

        request(true).get(`${endpoints.GAP_SITES_ALERTS_GET_CUSTOMER}?uprn=${state.uprn}`).then(e => {
          if (!e.data || !e.data.customer) {
            window.alert(`No data found for UPRN ${state.uprn}`)
            dispatch({ type: 'clear_screen' })
            return
          }

          dispatch({ type: 'search_results', payload: e.data })
        })
      }, [state.uprn])

      const doAccountSearch = useCallback(() => {
        if (!state.accountNumber) return

        request(true).get(`${endpoints.GAP_SITES_ALERTS_GET_CUSTOMER}?accountNumber=${state.accountNumber}`).then(e => {
          if (!e.data || !e.data.customer) {
            window.alert(`No data found for account number ${state.accountNumber}`)
            dispatch({ type: 'clear_screen' })
            return
          }

          dispatch({ type: 'search_results', payload: e.data })
        })
      }, [state.accountNumber])

      const handleSubmitCustomer = useCallback(() => {
        if (!state.customer) return

        if (state.rejectionReason === "Border Postcode") {
          request(true).post(endpoints.GAP_SITES_ALERTS_REJECT_POSTCODE, {
            Customer: state.customer.ABP_UPRN,
            RejectionReason: state.rejectionReason,
          }).then(e => {
              dispatch({ type: 'submit_customer' })
              window.alert('Details successfully updated')
              updateWorkflowInfo()
            }).catch(e => {
              console.log(e);
              window.alert('Failed to submit')
            })
          return
        }

        request(true).post(endpoints.GAP_SITES_ALERTS_UPDATE_CUSTOMER, {
          Customer: state.customer.ABP_UPRN,
          State: state.gapStatus,
          RejectionReason: state.rejectionReason,
          PropertyType: state.propertyType,
          ABP_ADDRESS_ID: state.abpAddressId,
        }).then(e => {
            dispatch({ type: 'submit_customer' })
            window.alert('Details successfully updated')
            updateWorkflowInfo()
        }).catch(e => {
            console.log(e);
            window.alert('Failed to submit')
          })
      }, [state.customer, state.gapStatus, state.rejectionReason, state.propertyType, state.abpAddressId])

      const handlePurchaseCustomer = useCallback(() => {
        if (!state.customer) return

        request(true).post(endpoints.GAP_SITES_ALERTS_BUY_CUSTOMER, {
          customer: state.customer.ABP_UPRN,
        }).then(e => {
            dispatch({ type: 'purchase_customer', payload: e.data.customer })
        }).catch(e => {
            console.log(e);
            window.alert('Failed to purchase')
          })
      }, [state.customer])

      const updateWorkflowInfo = useCallback(() => {
        request(true).get(endpoints.GAP_SITES_ALERTS_WF_COUNTS, {
          params: {
            state: state.workflowTab,
          }
        }).then(e => {
            dispatch({ type: 'get_workflow_counts', payload: e.data })
          }).catch(e => {
            console.log(e)
            window.alert("Failed to get workflow counts")
          })

        request(true).get(endpoints.GAP_SITES_ALERTS_WF_ITEMS, {
          params: {
            state: state.workflowTab,
          }
        }).then(e => {
            dispatch({ type: 'get_workflow_items', payload: e.data })
          }).catch(e => {
            console.log(e)
            window.alert("Failed to get workflow items")
          })
      }, [state.workflowTab])

      const handleSubmitNotes = useCallback(() => {
        if (!state.notes || !state.customer) return

        request(true).post(endpoints.GAP_SITES_ALERTS_SUBMIT_NOTES, {
          ABP_UPRN: state.customer.ABP_UPRN,
          notes: state.notes,
        }).then(e => {
            dispatch({ type: "submit_notes" })
            window.alert("Note submitted")
          }).catch(e => {
            console.log(e)
            window.alert("Failed to submit note")
          })
      }, [state.customer, state.notes])

      useEffect(() => {
        if (match?.params?.ABP_UPRN) {
          dispatch({ type: 'set_uprn', payload: match.params.ABP_UPRN })
        }
      }, [match?.params?.ABP_UPRN])

      useEffect(() => {
        updateWorkflowInfo()
      }, [updateWorkflowInfo])

      useEffect(() => {
        doUprnSearch()
      }, [state.workflowIndex, state.workflowItems])

      return (
        <GenericPageContainer
          title={`${translations ? translations['module_title'] : ''} - Workflow`}
          titleRight={
            <div>
              <Link className="navigate" to="/portal/gap-sites-alerts">
                To Summary
              </Link>
              { (hasPermission('GSA', 'BILLING_SEARCH')) ?
                <Link className="navigate" to="/portal/gap-sites-alerts/billing-search">
                  To Billing Search
                </Link>
                : null }
              { (hasPermission('GSA', 'WORKFLOW')) ?
                <Link className="navigate" to="/portal/gap-sites-alerts/search">
                  To Search
                </Link>
                : null }
              { hasPermission('GSA', 'DASHBOARD') ?
                <Link className="navigate" to="/portal/gap-sites-alerts/dashboard">
                  To Dashboard
                </Link>
                : null }
            </div>
          }
          containerWidth={"90vw"}
        >
          <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: `repeat(${statuses.length}, auto)`, marginInline: '30em', marginBottom: '15px' }}>
            {
              statuses.map(statusName => {
                const count = state.workflowCounts?.find(wfCount => wfCount?.state === statusName)
                const displayName = statusName === '' ? "New" : statusName
                return (
                  <button className={`button compact smaller-text ${state.workflowTab === statusName ? "bulk-buy-button" : "background-primary"} colour-white`} onClick={() => {
                    dispatch({ type: 'select_workflow_tab', payload: statusName })
                  }}>
                    {displayName} ({count?.count ?? 0})
                  </button>
                )
              })
            }
          </div>
          <div className="gsa" style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            { state.loading ?
              <i style={{ marginTop: '10px', marginLeft: '10px', position: 'absolute', top: '15vh', left: '50vw' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
              : ''}
            <div>
              <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
                <col width={180} />
                <thead>
                  <tr>
                    <th colSpan={10}>{translations['Header_Summary']}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width={400}>{translations['UDPRN']}</td>
                    <td colSpan={2}>
                      <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                        {/* <input disabled style={{border: 'none', backgroundColor: 'white'}} type="text" value={customer?.ABP_UPRN} /> */}
                        <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_uprn', payload: e.target.value })} value={state.uprn} />
                        {
                          <>
                            <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={() => dispatch({ type: 'workflow_prev' }) }></span>
                            <span>{state.workflowIndex + 1}/{state.workflowLength}</span>
                            <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={() => dispatch({ type: 'workflow_next' })}></span>
                            <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={!state.loading ? doUprnSearch : null}></span>
                          </>
                        }
                      </div>
                    </td>
                    <td width={400}>{translations['Account_Number']}</td>
                    <td colSpan={2}>
                      <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                        <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_account_number', payload: e.target.value })} value={state.accountNumber} />
                        {
                          <>
                            <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={!state.loading ? doAccountSearch : null}></span>
                          </>
                        }
                      </div>
                    </td>
                    <td>{translations['category']}</td>
                    <td colSpan={1}>
                      {state.customer ? state.customer.Category : ''}
                      {/* <select value={customer ? customer.Category : null} onChange={_ => setCategory(_.target.value)} style={{width: '100%', border: 'none'}}>
                                                <option value={"Address Matched"}>Address Matched</option>
                                                <option value={"Aligned Mismatch"}>Aligned Mismatch</option>
                                                <option value={"Bulk Meter"}>Bulk Meter</option>
                                                <option value={"New Connections"}>New Connections</option>
                                                <option value={"Split Property"}>Split Property</option>
                                                <option value={"Flat Above Shop"}>Flat Above Shop</option>
                                                <option value={"Potential Gap"}>Potential Gap</option>
                                                <option value={"Commercial"}>Commercial</option>
                                                <option value={"Commercial Input"}>Commercial Input</option>
                                                <option value={"Exclude"}>Exclude</option>
                                            </select> */}
                    </td>
                    <td colSpan={2} rowSpan={11} style={{height: '100%'}}>
                      <div style={{position: 'relative', height: '100%'}}>
                        <textarea onChange={(event) => dispatch({ type: 'set_notes', payload: event.target.value })} value={state.notes ?? ''} rows={18} style={{height: '100%', width: '98%', resize: 'none'}}/>
                        <div style={{ position: 'absolute', bottom: 10, right: 5, color: 'gray', fontSize: 12 }}>
                          {state.notes?.length}/500
                        </div>
                      </div>
                      <button 
                        disabled={state.notes?.length < 0} 
                        className={`button compact smaller-text ${state.notes?.length > 0 ? 'background-primary colour-white' : ''}`}
                        onClick={handleSubmitNotes}
                      >Submit Notes</button>
                    </td>
                  </tr>
                  <tr>
                    {/* <td colSpan={2}></td> */}
                    <td>{translations['Status']}</td>
                    <td colSpan={2}>
                      <select value={state.customer ? state.gapStatus ? state.gapStatus : state.customer.state : null} onChange={_ => dispatch({ type: 'set_gap_status', payload: _.target.value })} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                        <option value={"New"}>New</option>
                        <option value={"In Progress"}>In Progress</option>
                        <option value={"GAP Accepted"}>GAP Accepted</option>
                        <option value={"GAP Rejected"}>GAP Rejected</option>
                      </select>
                    </td>
                    {
                      state.gapStatus === "GAP Rejected" ?
                        <>
                          <td colSpan={2}>Rejection Reason</td>
                          <td colSpan={2}>
                            <select disabled={state.loading} value={state.customer ? state.rejectionReason ? state.rejectionReason : state.customer.state : null} onChange={_ => dispatch({ type: 'set_rejection_reason', payload: _.target.value })} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                              <option value={""}>Select Reason</option>
                              <option value={"Bulk account"}>Bulk account</option>
                              <option value={"New Accounts Process"}>New Accounts Process</option>
                              <option value={"Property is billing - Address Quality"}>Property is billing - Address Quality</option>
                              <option value={"Property is billing - No Action Required"}>Property is billing - No Action Required</option>
                              <option value={"Query with IDenteq"}>Query with IDenteq</option>
                              <option value={"MOD/Private Supply"}>MOD/Private Supply</option>
                              <option value={"Border Postcode"}>Border Postcode</option>
                              <option value={"Other"}>Other</option>
                            </select>
                          </td>
                        </>
                        :
                        state.gapStatus === "GAP Accepted" && session.company.gsa_use_prop_type ?
                          <>
                            <td colSpan={2}>Property Type</td>
                            <td colSpan={2}>
                              <select disabled={state.loading} value={state.customer ? state.propertyType ? state.propertyType : state.customer.state : null} onChange={_ => dispatch({ type: 'set_property_type', payload: _.target.value })} style={{width: '100%', border: 'none', backgroundColor: 'var(--light-gray)'}}>
                                <option value={""}>Select Type</option>
                                <option value={"DT"}>Detached</option>
                                <option value={"SD"}>Semi-Detached</option>
                                <option value={"TE"}>Terraced</option>
                                <option value={"OT"}>Other</option>
                              </select>
                            </td>
                          </>
                          :
                          <>
                            <td colSpan={4}></td>
                          </>
                    }
                    <td style={{textAlign: 'right'}} colSpan={'1'}>
                      { 
                        state.customer && 
                          state.gapStatus !== "New" && 
                          state.gapStatus !== null && 
                          !state.loading && 
                          (state.gapStatus === "GAP Rejected" ? state.rejectionReason : true) && 
                          (state.gapStatus === "GAP Accepted" && session.company.gsa_use_prop_type ? state.propertyType : true) && 
                          hasPermission('GSA', 'PURCHASE') ?
                          <button className='button background-primary colour-white' style={{padding: '0'}} onClick={handleSubmitCustomer}>
                            Submit
                          </button>
                          :
                          <button disabled={true} className='button disabled' style={{padding: '0'}}>
                            Submit
                          </button>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{fontWeight: 'bold'}} colSpan={3}>ABP Gap Address</td>
                    <td style={{fontWeight: 'bold'}} colSpan={2}>Matched CT Address</td>
                    <td style={{fontWeight: 'bold'}} colSpan={3}>{translations['occupier_details']}</td>
                  </tr>
                  <tr>
                    <td>{translations['Sub_Building']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.SUB_BUILDING : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_sub_building : null}</td>
                    <td>{translations['title']}</td>
                    <td colSpan={'2'}>{state.customer ? state.occupierDetails?.Title : null}</td>
                  </tr>
                  <tr>
                    <td>{translations['Building_Name']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.BUILDING_NAME : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_building_name : null}</td>
                    <td>{translations['forename']}</td>
                    <td colSpan={'2'}>{state.customer ? state.occupierDetails?.Forename : null}</td>
                  </tr>
                  <tr>
                    <td>{translations['Building_Number']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.BUILDING_NUMBER : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_building_number : null}</td>
                    <td>{translations['surname']}</td>
                    <td colSpan={'2'}>{state.customer ? state.occupierDetails?.Surname : null}</td>
                  </tr>
                  <tr>
                    <td>{translations['Street_Name']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.STREET_NAME : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_street_name : null}</td>
                    <td>{translations['date_of_birth']}</td>
                    <td colSpan={'2'}>{state.customer ? state.occupierDetails?.Date_of_Birth : null}</td>
                  </tr>
                  <tr>
                    <td>{translations['Locality']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.LOCALITY : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_locality : null}</td>
                    <td>{translations['landline']}</td>
                    <td colSpan={'2'}>{state.customer ? state.occupierDetails?.Landline : null}</td>
                  </tr>
                  <tr>
                    <td>{translations['Town_Name']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.TOWN_NAME : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_town_name : null}</td>
                    <td>{translations['mobile']}</td>
                    <td colSpan={'2'}>{state.customer ? state.occupierDetails?.Mobile : null}</td>
                  </tr>
                  <tr>
                    <td>{translations['Post_Town']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.POST_TOWN : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_post_town : null}</td>
                    <td>{translations['occupier_score']}</td>
                    <td colSpan={'2'}>{ state.occupierDetails?.Occupier_Score ? `${state.occupierDetails.Occupier_Score} ${state.occupierDetails.Occupier_Score === "1" ? "Star" : "Stars"}` : null }</td>
                  </tr>
                  <tr>
                    <td>{translations['Postcode']}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.POSTCODE : null}</td>
                    <td colSpan={'2'}>{state.customer ? state.customer.ct_postcode : null}</td>
                    <td colSpan={'3'}>
                      <div className='button-container'>
                        <button 
                          className={`button${!state.occupierDetails?.UPRN ? ' disabled' : ' active'}`} 
                          disabled={!state.occupierDetails?.UPRN || state.loading} onClick={() => setDetailsModalIsOpen(true)}>
                          {translations['occupier_footprint']}
                        </button>
                        <button 
                          className={`button${!state.occupierDetails?.UPRN || (state.occupierDetails?.Date_Bought && state.occupierDetails.Date_Bought != "0000-00-00")  ? ' disabled' : ' active'}`} 
                          disabled={(!state.occupierDetails?.UPRN || (state.occupierDetails?.Date_Bought && state.occupierDetails?.Date_Bought !== "0000-00-00") ) || state.loading} onClick={handlePurchaseCustomer}>
                          {translations['purchase_customer']}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: '17fr 16fr 14fr', gridAutoFlow: 'column', gap: 10}}>
              <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                <table className='table borders smaller-text squish'>
                  <thead style={{position: 'sticky', top: '0px'}}>
                    <tr>
                      <th>Client Addresses</th>
                      <th style={{width: '20%'}}>Status</th>
                      <th style={{width: '10%'}}>Match</th>
                    </tr>
                  </thead>
                  <tbody style={{whiteSpace: 'nowrap'}}>
                    {
                      state.addressData  ? 
                        state.addressData.client.map((address, index) => {
                          return (
                            <tr style={{height: '35px'}}>
                              <td style={{textAlign: 'left', fontSize: '12px'}}>{address.ClientAddress}</td>
                              <td style={{fontSize: '12px'}}>{typeof address.match !== 'undefined' ? address.match === 1 ? "Matched" : "Unmatched" : ""}</td>
                              <td><input type="checkbox" name={address.ClientAddress} id={address.ADDRESS_ID} onChange={ address.ClientAddress ? (e) => dispatch({ type: 'select_match_address', payload: e.target }) : null } checked={ 
                                state.matchedAddress ?
                                  address.ClientAddress === state.matchedAddress
                                  :
                                  address.ClientAddress == state.customer?.Client_Address
                              }></input></td>
                            </tr>
                          )
                        })
                        :
                        <tr><td></td><td></td></tr>
                    }
                  </tbody>
                </table>
              </div>
              <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                <table className='table borders smaller-text squish'>
                  <thead style={{position: 'sticky', top: '0px'}}>
                    <tr>
                      <th>ABP Addresses</th>
                    </tr>
                  </thead>
                  <tbody style={{whiteSpace: 'nowrap'}}>
                    {
                      state.addressData ? 
                        state.addressData.abp.map((address, index) => {
                          return (
                            <tr style={{height: '35px'}}>
                              <td style={{textAlign: 'left', fontSize: '12px'}}>{address.ABP_Address}</td>
                            </tr>
                          )
                        })
                        :
                        <tr><td></td></tr>
                    }
                  </tbody>
                </table>
              </div>
              <div style={{overflowX: 'auto', maxHeight: '50vh'}}>
                <table className='table borders smaller-text squish'>
                  <thead style={{position: 'sticky', top: '0px'}}>
                    <tr>
                      <th>Council Tax Addresses</th>
                    </tr>
                  </thead>
                  <tbody style={{whiteSpace: 'nowrap'}}>
                    {
                      state.addressData  ? 
                        state.addressData.ct.map((address, index) => {
                          return (
                            <tr style={{height: '35px'}}>
                              <td style={{textAlign: 'left', fontSize: '12px'}}>{address.FullAddress}</td>
                            </tr>
                          )
                        })
                        :
                        <tr><td></td></tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            { state.showMap ?
              <div style={{minHeight: '40vh'}}>
                <PropertyMap address={ state.customer ? state.customer.ABP_Address : null } />
              </div>
              :
              <>
                <div className="map hidden" onClick={ () => { if (state.customer) { dispatch({ type: 'show_map', payload: true }) }} }>
                  { state.customer ? 'Click to show map' : '' }
                </div>
              </>
            }
          </div>
          <ReactModal
            className="card bulk-buy"
            isOpen={detailsModalIsOpen}
            onRequestClose={() => setDetailsModalIsOpen(false)}
            contentLabel="Customer details modal"
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' }, content: { width: '66vw' } }}
          >
            <div className='gsa-occupier-details'>
              <table className="table borders squish smaller-text left">
                <thead>
                  <tr>
                    <th>{translations['occupier_footprint']}</th>
                    <th style={{width: '50%'}}>Evidence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Listed on electoral roll
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Electoral_Roll : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Listed on BT register
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.BT_Line : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of secured loans
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Secured_Loans : '' }

                    </td>
                  </tr>
                  <tr>
                    <td>
                      Named on Title Deed
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Land_Registry : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      GAS redirect in place
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.GAS_Redirect : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      NCOA redirect in place
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.NCOA_Redirect : '' }

                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of bank accounts
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Bank_Accounts : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of unsecured loans
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Unsecured_Loans : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of utility accounts
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Utility_Accounts : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of telco/media accounts
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Telco_Accounts : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of home credit accounts
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Home_Credit_Accounts : '' }
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="table borders squish smaller-text left">
                <thead>
                  <tr>
                    <th>{translations['occupier_footprint']}</th>
                    <th style={{width: '50%'}}>Evidence</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      Count of mail order accounts
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Mail_Order : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of revolving facilities
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Revolving_Facilities : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of other facilities
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Other_Accounts : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Count of recent credit searches
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Credit_Searches : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Company House Director
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.CH_Director : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Data on Demand Loans
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.DoD_Loans : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Data on Demand Subscriptions
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.DoD_Subscriptions : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Latest date linked to property
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Latest_Date : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Earliest date linked to property
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Earliest_Date : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Occupier Score
                    </td>
                    <td>
                      { state.occupierDetails?.Occupier_Score ? `${state.occupierDetails.Occupier_Score} ${state.occupierDetails.Occupier_Score === "1" ? "Star" : "Stars"}` : '' }
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Occupier Score Change
                    </td>
                    <td>
                      { state.occupierDetails ? state.occupierDetails.Occupier_Change : '' }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </ReactModal>
        </GenericPageContainer>
      )
    }
  )
);
