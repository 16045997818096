import React, { Component } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Card from '../../components/card/card'
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import { withRouter, matchPath } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox';
import { FormControlLabel } from '@material-ui/core'
import session from '../../stores/session';

export default withRouter(class UserPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            match: matchPath(props.history.location.pathname, {
                path: '/admin/portal/users/:user',
            }),
            user: {
                id: null,
                name: '',
                email: '',
                password: '',
                repeat_password: '',
                permissions: [],
            },
            message: null,
            errors: {
                name: [],
                email: [],
                password: [],
            },
            enableDeleteField: false,
            realProducts: [],
            extraPermissions: [],
        }
    }

    fetchUser = state => {
        request(true).get(endpoints.ADMIN_COMPANIES_USER + '/' + this.state.match.params.user).then(r => {
            this.setState({
                user: { ...r.data, password: '', repeat_password: '' },
                ...state,
            });
        }).catch(r => {
            console.log(r);
        });
    }

    componentDidMount() {
        this.fetchUser();
        this.loadRealProducts();
        this.loadExtraPermissions();
    }

    updateFormValue = e => {
        let {passwordStrong} = this.state;

        if (e.target.name === 'password') {
            if (e.target.value.match(/(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/)) {
                passwordStrong = true;
            } else {
                passwordStrong = false;
            }
        }

        this.setState({
            user: {
                ...this.state.user,
                [e.target.name]: e.target.value ? e.target.value : undefined,
            },
            passwordStrong,
        }, () => {
            console.log(this.state);
        });
    }

    formSubmit = () => {
        const postBody = {};
        postBody.name = this.state.user.name;
        postBody.email = this.state.user.email;
        postBody.permissions = this.state.user.permissions;
        postBody.extra_permissions = this.state.user.extra_permissions;
        postBody.user_type = this.state.user.user_type;
        postBody.retailer = this.state.user.retailer;

        if (this.state.user.password) {
            postBody.password = this.state.user.password;
            if (this.state.user.password !== this.state.user.repeat_password) {
                this.setState({
                    errors: {
                        repeat_password: ['Passwords do not match'],
                        ...this.state.errors,
                    },
                    message: '',
                });

                return;
            }
                this.setState({
                    errors: {
                        repeat_password: [],
                        ...this.state.errors,
                    },
                    message: '',
                });
        }

        request(true).post(endpoints.ADMIN_COMPANIES_USER + '/' + this.state.match.params.user, postBody).then(r => {
            this.fetchUser({ message: 'User Updated!'});
        }).catch(r => {
            console.log(r.response);
            this.setState({ message: 'Error Updating User', errors: r.response.data.errors });
        });
    }

    deleteUser = () => {
        if (this.state.enableDeleteField) {
            request(true).delete(endpoints.ADMIN_COMPANIES_USER + '/' + this.state.match.params.user).then(r => {
                console.log(r);
                this.props.history.goBack();
            }).catch(r => {
                console.error(r);
            })
        } else {
            this.setState({ enableDeleteField: true });
        }
    }

    loadRealProducts = () => {
        request(true).get(endpoints.ADMIN_REAL_PRODUCTS_INDEX).then(r => {
            let productList = r.data;
            request(true).get(endpoints.PRODUCTS).then(r => {
                productList = productList.filter(companyProduct => r.data.find(product => product.id == companyProduct.product_offering_id && product.available));

                productList = productList.map(companyProduct => ({
                    ...companyProduct,
                    name: r.data.find(product => product.id == companyProduct.product_offering_id).name,
                    icon: r.data.find(product => product.id == companyProduct.product_offering_id).icon,
                }))

                request(true).get(endpoints.ADMIN_COMPANIES_COMPANY + '/' + this.state.user.company.id + '/products').then(r => {
                    productList = productList.filter(companyProduct => r.data.products.find(product => product.id == companyProduct.product_offering_id && product.enabled));
                    this.setState({
                        realProducts: productList,
                    });
                })
            }).catch(e => {
                console.error(e);
            });
        });
    }

    loadExtraPermissions = () => {
        request(true).get(endpoints.ADMIN_EXTRA_PERMISSIONS).then(r => {
            let permissionList = r.data.filter(item => item.key.substring(0, 5) !== 'ADMIN');
            const adminPermissionList = r.data.filter(item => item.key.substring(0, 5) === 'ADMIN');

            request(true).get(endpoints.ADMIN_REAL_PRODUCTS_INDEX).then(r => {
                const real_products = r.data

                request(true).get(endpoints.PRODUCTS).then(r => {
                    permissionList = permissionList.filter(permission => r.data.find(product => product.id == real_products.find(real_product => real_product.id == permission.real_product_id).product_offering_id && product.available))

                    permissionList = permissionList.map(permission => ({
                        ...permission,
                        icon: r.data.find(product => product.id == real_products.find(real_product => real_product.id == permission.real_product_id).product_offering_id).icon,
                    }))

                    this.setState({
                        extraPermissions: permissionList.concat(adminPermissionList),
                    })
                })
            })
        })
    }

    updatePermissionValue = event => {
        const temp = [].concat(this.state.user.permissions);

        if (event.target.checked) {
            if (this.state.user.permissions.indexOf(parseInt(event.target.name)) === -1) {
                temp.push(parseInt(event.target.name));
            }
        } else if (this.state.user.permissions.indexOf(parseInt(event.target.name)) !== -1) {
                temp.splice(this.state.user.permissions.indexOf(parseInt(event.target.name)), 1);
            }

        this.setState({
            user: {
                ...this.state.user,
                permissions: temp,
            },
        });
    }

    updateExtraPermissionValue = event => {
        const temp = [].concat(this.state.user.extra_permissions);

        if (event.target.checked) {
            if (this.state.user.extra_permissions.indexOf(parseInt(event.target.name)) === -1) {
                temp.push(parseInt(event.target.name));
            }
        } else if (this.state.user.extra_permissions.indexOf(parseInt(event.target.name)) !== -1) {
                temp.splice(this.state.user.extra_permissions.indexOf(parseInt(event.target.name)), 1);
            }

        this.setState({
            user: {
                ...this.state.user,
                extra_permissions: temp,
            },
        });
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                { this.state.user.id !== null
                ? <Container width="90vw">
                    <div className="flex middle">
                        <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>{ this.state.user.name } @ { this.state.user.company ? this.state.user.company.name : 'Administrator' }</p>
                    </div>
                        <Card>
                            <div className="grid grid-gap-15">
                                <div className="form-row">
                                    <label htmlFor="name">Company:</label>
                                    <input name="name" placeholder="Company" className="input readonly" type="text" value={ this.state.user.company ? this.state.user.company.name : 'Administrator' } required readOnly/>
                                </div>
                                <div className="form-row">
                                    <label htmlFor="name">Name:</label>
                                    <input onChange={this.updateFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.user.name} required/>
                                    { this.state.errors.name ? this.state.errors.name.map((value, index) => (
                                            <p key={index}>
                                                { value }
                                            </p>
                                        )) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="email">Email:</label>
                                    <input onChange={this.updateFormValue} name="email" placeholder="Email" className="input" type="text" value={this.state.user.email} required/>
                                    { this.state.errors.email ? this.state.errors.email.map((value, index) => (
                                            <p className="error" key={index}>
                                                { value }
                                            </p>
                                        )) : <></>}
                                </div>
                                <div style={{display: 'flex'}}>
                                    {this.state.user ? this.state.user.password_expires_in > 0 ? <p className="background-light-gray" style={{padding: 5, borderRadius: 3}}>Password expires in {this.state.user.password_expires_in} day(s).</p> : <p className="background-red colour-white" style={{padding: 5, borderRadius: 3}}>Password expired</p> : null}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="password">Password:</label>
                                    <input onChange={this.updateFormValue} name="password" placeholder="Password" className={`input ${this.state.passwordStrong ? 'color-green' : ''}`} type="password" value={this.state.user.password} required/>
                                    <small>Passwords must be at least 12 characters long and contain at least: 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</small>
                                    { this.state.errors.password ? this.state.errors.password.map((value, index) => (
                                            <p key={index}>
                                                { value }
                                            </p>
                                        )) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="repeat_password">Repeat Password:</label>
                                    <input onChange={this.updateFormValue} name="repeat_password" placeholder="Password" className="input" type="password" value={this.state.user.repeat_password} required/>
                                    { this.state.errors.repeat_password ? this.state.errors.repeat_password.map((value, index) => (
                                            <p key={index}>
                                                { value }
                                            </p>
                                        )) : <></>}
                                </div>
                                <div className="form-row">
                                    <label htmlFor="user_type">User Type:</label>
                                    <select onChange={this.updateFormValue} name="user_type" className="input" value={ this.state.user.user_type ?? '' } required>
                                        <option value="Agent">Agent</option>
                                        <option value="Team Leader">Team Leader</option>
                                        <option value="">Custom</option>
                                    </select>
                                </div>
                                <div className="form-row">
                                    <label htmlFor="retailer">Retailer:</label>
                                    <select disabled={session.company !== null} onChange={this.updateFormValue} name="retailer" className="input" value={ this.state.user.retailer ?? '' } required>
                                        <option value="">None</option>
                                        <option value="NORTHUM-R">Northum</option>
                                        <option value="UNITED-R">United</option>
                                        <option value="SEVERN-R">Severn</option>
                                        <option value="WESSEX-R">Wessex</option>
                                        <option value="SOUTHSTAFF-R">Southstaff</option>
                                        <option value="ANGLIAN-R">Anglian</option>
                                        <option value="SOUTHWEST-R">South West</option>
                                        <option value="THAMES-R">Thames</option>
                                        <option value="SOUTHERN-R">Southern</option>
                                        <option value="AFFINITY-R">Affinity</option>
                                        <option value="SOUTHEAST-R">South East</option>
                                        <option value="BRISTOL-R">Bristol</option>
                                        <option value="YORKSHIRE-R">Yorkshire</option>
                                        <option value="PORTSMOUTH-R">Portsmouth</option>
                                        <option value="SUTTON-R">Sutton</option>
                                    </select>
                                </div>
                                { this.state.user.company && this.state.realProducts.length && this.state.extraPermissions.length
                                ? <>

                                    { this.state.realProducts.filter(item => item.icon === 'fak fa-id-house-icon').length
                                        ? <div className="form-row">
                                            <strong>COT Portal Products</strong>
                                            <table className="table borders permissions" id="cot-portal">
                                                <thead>
                                                    <tr>
                                                    <th>Product</th>
                                                        <th>Product Access</th>
                                                        <th>Download</th>
                                                        <th>Upload</th>
                                                        <th>Workflow</th>
                                                        <th>Allocate</th>
                                                        <th>Bulk Buy</th>
                                                        <th>Purchase</th>
                                                        <th>NFI Check</th>
                                                        <th>NFI Search</th>
                                                        <th>Workflow Combined</th>
                                                        <th>Manual Review</th>
                                                        <th>Dashboard</th>
                                                        <th>Unallocate</th>
                                                        <th>Billing Search</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.state.realProducts.filter(realProduct => realProduct.icon === 'fak fa-id-house-icon').map(realProduct => (
                                                            <tr>
                                                                <td>
                                                                    { realProduct.name }
                                                                </td>
                                                                <td className="item" key={`permission-${realProduct.product_key}`}>
                                                                    <Checkbox
                                                                      key={'checkbox_' + realProduct.id}
                                                                      color='primary'
                                                                      checked={ this.state.user.permissions.indexOf(parseInt(realProduct.id)) !== -1 }
                                                                      onChange={ this.updatePermissionValue }
                                                                      name={`${realProduct.id}`}
                                                                    />
                                                                </td>
                                                                {
                                                                    ['DOWNLOAD', 'UPLOAD', 'WORKFLOW', 'ALLOCATE', 'BULK-BUY', 'PURCHASE', 'NFI-CHECK', 'NFI-SEARCH', 'WORKFLOW-COMBINED', 'MANUAL-REVIEW', 'DASHBOARD', 'UNALLOCATE', 'BILLING_SEARCH'].map(key => this.state.extraPermissions.filter(permission => permission.real_product_id === realProduct.id).find((permission => permission.key.substring(permission.key.indexOf('-') + 1) == key))).map(permission => (
                                                                                    permission
                                                                                    ? <td className="item" key={`permission-${permission.key}-${permission.id}`} style={this.state.user.user_type != null ? {opacity: '50%'} : null}>
                                                                                        <FormControlLabel
                                                                                          key={'label_' + permission.id}
                                                                                            // label={permission.key.replace('-', ' ').replace('_', ' ')}
                                                                                          name={permission.id}
                                                                                          control={<Checkbox
                                                                                            key={'checkbox_' + permission.id}
                                                                                            color='primary'
                                                                                            checked={
                                                                                                    (this.state.user.user_type === 'Team Leader')
                                                                                                    || (this.state.user.user_type === 'Agent' && ((permission.key.substring(permission.key.indexOf('-') + 1) === 'WORKFLOW') || permission.key.substring(permission.key.indexOf('-') + 1) === 'PURCHASE'))
                                                                                                    || (this.state.user.extra_permissions.indexOf(parseInt(permission.id)) !== -1 && this.state.user.user_type == null)
                                                                                                }
                                                                                            disabled={this.state.user.user_type != null}
                                                                                            onChange={ this.updateExtraPermissionValue }
                                                                                            name={permission.id}
                                                                                          />}
                                                                                        />
                                                                                    </td>
                                                                                    : <td />
                                                                                ))
                                                                }
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    : null }

                                    <br/>

                                    { this.state.realProducts.filter(item => item.icon === 'fak fa-id-business-icon').length
                                        ? <div className="form-row">
                                            <strong>BD Portal Products</strong>
                                            <table className="table borders permissions" id="bd-portal">
                                                <thead>
                                                    <tr>
                                                    <th>Product</th>
                                                        <th>Product Access</th>
                                                        <th>Download</th>
                                                        <th>Upload</th>
                                                        <th>Workflow</th>
                                                        <th>Allocate</th>
                                                        <th>Bulk Buy</th>
                                                        <th>Purchase</th>
                                                        <th>Dashboard</th>
                                                        <th>Unallocate</th>
                                                        <th>Priority Allocate</th>
                                                        <th>Priority Unallocate</th>
                                                        {
                                                            this.state.realProducts.some(realProduct => realProduct.product_key === 'household_property')
                                                                ? <>
                                                                    <th>Wholesaler</th>
                                                                    <th>Retailer</th>
                                                                </>
                                                            : <></>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.state.realProducts.filter(realProduct => realProduct.icon === 'fak fa-id-business-icon').map(realProduct => {
                                                        const permissions = ['DOWNLOAD', 'UPLOAD', 'WORKFLOW', 'ALLOCATE', 'BULK-BUY', 'PURCHASE', 'DASHBOARD', 'UNALLOCATE', 'PRIORITY-ALLOCATE', 'PRIORITY-UNALLOCATE'];
                                                        console.log(realProduct)
                                                        if (this.state.realProducts.some(realProduct => realProduct.product_key === 'household_property')) {
                                                            permissions.push('WHOLESALER', 'RETAILER')
                                                        }
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    { realProduct.name }
                                                                </td>
                                                                <td className="item" key={`permission-${realProduct.product_key}`}>
                                                                    <Checkbox
                                                                      key={'checkbox_' + realProduct.id}
                                                                      color='primary'
                                                                      checked={ this.state.user.permissions.indexOf(parseInt(realProduct.id)) !== -1 }
                                                                      onChange={ this.updatePermissionValue }
                                                                      name={`${realProduct.id}`}
                                                                    />
                                                                </td>
                                                                {
                                                                    permissions.map(key => this.state.extraPermissions.filter(permission => permission.real_product_id === realProduct.id).find((permission => permission.key.substring(permission.key.indexOf('-') + 1) == key))).map(permission => (
                                                                                    permission
                                                                                    ? <td className="item" key={`permission-${permission.key}-${permission.id}`} style={this.state.user.user_type != null ? {opacity: '50%'} : null}>
                                                                                        <FormControlLabel
                                                                                          key={'label_' + permission.id}
                                                                                            // label={permission.key.replace('-', ' ').replace('_', ' ')}
                                                                                          name={permission.id}
                                                                                          control={<Checkbox
                                                                                            key={'checkbox_' + permission.id}
                                                                                            color='primary'
                                                                                            checked={
                                                                                                    (this.state.user.user_type === 'Team Leader')
                                                                                                    || (this.state.user.user_type === 'Agent' && ((permission.key.substring(permission.key.indexOf('-') + 1) === 'WORKFLOW') || permission.key.substring(permission.key.indexOf('-') + 1) === 'PURCHASE'))
                                                                                                    || (this.state.user.extra_permissions.indexOf(parseInt(permission.id)) !== -1 && this.state.user.user_type == null)
                                                                                                }
                                                                                            disabled={this.state.user.user_type != null}
                                                                                            onChange={ this.updateExtraPermissionValue }
                                                                                            name={permission.id}
                                                                                          />}
                                                                                        />
                                                                                    </td>
                                                                                    : <td />
                                                                                ))
                                                                }
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    : null }

                                    <br/>

                                    { session.company === null
                                        ? <div className="form-row">
                                            <strong>Admin</strong>
                                                {
                                                    this.state.extraPermissions
                                                        ? this.state.extraPermissions.filter(item => item.key.substring(0, 5) === 'ADMIN').map(permission => (
                                                                <div className="item" key={`permission-${permission.key}-${permission.id}`}>
                                                                    { permission.key.replaceAll('-', ' ') }: <input type="checkbox" name={ permission.id } key={'checkbox_' + permission.id} onChange={ this.updateExtraPermissionValue } checked={ this.state.user.extra_permissions.indexOf(parseInt(permission.id)) !== -1 }/>
                                                                </div>
                                                            ))
                                                    : null
                                                }
                                        </div>
                                    : null}
                                </>
                                : '' }
                                <button onClick={this.formSubmit} className="button background-primary colour-white">Update</button>
                                <p className="text-center">{ this.state.message }</p>
                                {
                                    Object.keys(this.state.errors).map(item => {
                                        if (this.state.errors[item]) {
                                            return (
                                                <p className="text-center">{ this.state.errors[item] }</p>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </Card>
                        <div className={ this.state.enableDeleteField ? 'delete enabled' : 'delete disabled'} style={{ textAlign: 'center', marginTop: '40px' }}>
                            <button onClick={this.deleteUser} className="button background-red colour-white">
                                <span style={{ marginRight: '20px' }}>{ this.state.enableDeleteField ? 'Are you sure?' : 'Delete This User' }</span>
                                <i className="fa fa-unlock" aria-hidden="true" />
                                <i className="fa fa-lock" aria-hidden="true" />
                            </button>
                        </div>
                </Container>
                : <></>}
                <Footer />
            </div>
        )
    }
})
