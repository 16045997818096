import React, { useCallback, useReducer } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer.jsx'
import Pagination from '../../../components/pagination/pagination.js'
import request from '../../../helpers/request.js'
import endpoints from '../../../helpers/endpoints.js'
import { view } from '@risingstack/react-easy-state'
import './GapSitesAlerts.scss';
import billingSearchReducer from './GapSitesAlertsBillingSearchReducer.js'

import { Link, withRouter } from 'react-router-dom'
import hasPermission from '../../../helpers/permissions.js';
import { useTranslations } from '../../../helpers/hooks.js'

export default view(
  withRouter(
    function GapSitesAlertsSearch() {
      const translations = useTranslations('gap_sites_alerts');

      const initialState = {
        loading: false,
        searchResults: [],
        page: {
          page: 1,
          last_page: null,
        },

        installationNumber: '',
        uprn: '',
        subBuildingName: '',
        buildingName: '',
        buildingNumber: '',
        thoroughfare: '',
        dependantLocality: '',
        postTown: '',
        postcode: '',
      }

      const [state, dispatch] = useReducer(billingSearchReducer, initialState)

      const handleSearch = useCallback((page = 1) => {
        request(true).get(`${endpoints.GAP_SITES_ALERTS_BILLING_SEARCH}`, {
          params: {
            page: page,

            installationNumber: state.installationNumber,
            uprn: state.uprn,
            subBuildingName: state.subBuildingName,
            buildingName: state.buildingName,
            buildingNumber: state.buildingNumber,
            thoroughfare: state.thoroughfare,
            dependantLocality: state.dependantLocality,
            postTown: state.postTown,
            postcode: state.postcode,
          }
        }).then(e => {
          if (!e.data || !e.data.data) {
            window.alert("No data found for search input")
            return
          }

          dispatch({ type: 'set_search_results', payload: e.data.data });
          dispatch({ type: 'update_page', 
            payload: {
              page: e.data.current_page,
              last_page: e.data.last_page
            } 
          })
        })
      }, [state.buildingName, state.buildingNumber, state.dependantLocality, state.installationNumber, state.postTown, state.postcode, state.subBuildingName, state.thoroughfare, state.uprn])

      return (
        <GenericPageContainer
          title={`${translations ? translations['module_title'] : ''} - Billing Search`}
          titleRight={
            <div>
              <Link className="navigate" to="/portal/gap-sites-alerts">
                To Summary
              </Link>
              { (hasPermission('GSA', 'WORKFLOW')) ?
                <Link className="navigate" to="/portal/gap-sites-alerts/search">
                  To Search
                </Link>
                : null }
              { hasPermission('GSA', 'WORKFLOW') ?
                <Link className="navigate" to="/portal/gap-sites-alerts/workflow">
                  To Workflow
                </Link>
                : null }
              { hasPermission('GSA', 'DASHBOARD') ?
                <Link className="navigate" to="/portal/gap-sites-alerts/dashboard">
                  To Dashboard
                </Link>
                : null }
            </div>
          }
          containerWidth={"90vw"}
        >
          <div className="gsa gsa--billing-summary" style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            { state.loading ?
              <i style={{ marginTop: '10px', marginLeft: '10px', position: 'absolute', top: '15vh', left: '50vw' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
              : ''}
            <div>
              <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
                <col width={180} />
                <thead>
                  <tr>
                    <th colSpan={10}>{translations['Header_Billing_Summary']}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width={400}>{translations['Installation_Number']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_installation_number', payload: e.target.value })} value={state.installationNumber} />
                    </td>
                    <td width={400}>{translations['UDPRN']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_uprn', payload: e.target.value })} value={state.uprn} />
                    </td>
                    <td width={400}>{translations['Sub_Building']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_sub_building_name', payload: e.target.value })} value={state.subBuildingName} />
                    </td>
                    <td colSpan={1}>
                      <button className='button background-primary colour-white' style={{padding: '0'}} onClick={handleSearch}>
                        Search
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td width={400}>{translations['Building_Name']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_building_name', payload: e.target.value })} value={state.buildingName} />
                    </td>
                    <td width={400}>{translations['Building_Number']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_building_number', payload: e.target.value })} value={state.buildingNumber} />
                    </td>
                    <td width={400}>{translations['Street_Name']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_thoroughfare', payload: e.target.value })} value={state.thoroughfare} />
                    </td>
                    <td colSpan={1}>
                    </td>
                  </tr>
                  <tr>
                    <td width={400}>{translations['Locality']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_dependant_locality', payload: e.target.value })} value={state.dependantLocality} />
                    </td>
                    <td width={400}>{translations['Post_Town']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_post_town', payload: e.target.value })} value={state.postTown} />
                    </td>
                    <td width={400}>{translations['Postcode']}</td>
                    <td colSpan={2}>
                      <input style={{border: 'none'}} type="text" onChange={e => dispatch({ type: 'set_postcode', payload: e.target.value })} value={state.postcode} />
                    </td>
                    <td colSpan={1}>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
                <col width={180} />
                <thead>
                  <tr>
                    <th colSpan={9}>{translations['Header_Results']}</th>
                  </tr>
                  <tr>
                    <td>{translations['Installation_Number']}</td>
                    <td>{translations['UDPRN']}</td>
                    <td>{translations['Sub_Building']}</td>
                    <td>{translations['Building_Name']}</td>
                    <td>{translations['Building_Number']}</td>
                    <td>{translations['Street_Name']}</td>
                    <td>{translations['Locality']}</td>
                    <td>{translations['Post_Town']}</td>
                    <td>{translations['Postcode']}</td>
                  </tr>
                </thead>
                <tbody>
                  { state.searchResults.length ? state.searchResults.map(result => (
                    <tr>
                      <td>{result.INSTALLATION_NUMBER}</td>
                      <td>{result.CLIENT_UPRN}</td>
                      <td>{result.SUB_BUILDING_NAME}</td>
                      <td>{result.BUILDING_NAME}</td>
                      <td>{result.BUILDING_NUMBER}</td>
                      <td>{result.THOROUGHFARE}</td>
                      <td>{result.DEPENDANT_LOCALITY}</td>
                      <td>{result.POST_TOWN}</td>
                      <td>{result.POSTCODE}</td>
                    </tr>
                  )) : <></>}
                </tbody>
              </table>
              <div className="flex center">
                <Pagination page={ state.page.page } pages={ state.page.last_page } pageChange={ handleSearch } />
              </div>
            </div>
          </div>
        </GenericPageContainer>
      )
    }
  )
);
