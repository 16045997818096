import React, { useCallback } from 'react'
import KpiCard from '../../../components/kpi-card/kpiCard'
import Page from '../../../components/page/page'
import './addressAlerts.scss';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import { useState } from 'react';
import { useEffect } from 'react';
import LargeLinkButton from '../../../components/large-link-button/largeLinkButton';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import { useTranslations } from '../../../helpers/hooks';

function AddressAlertsKPIs() {
    const translations = useTranslations('address_alerts')
    const [KPIs, setKPIs] = useState(null)
    // Get KPIs
    useEffect(() => {
      request(true).get(endpoints.ADDRESS_ALERTS_GET_KPIS).then(e => { setKPIs(e.data) }).catch(e => window.alert('Error loading module.'))
    }, [])
    return (
    <div className="grid grid-columns-5 grid-gap-15">
        <KpiCard
        primary={KPIs ? KPIs.Voids_Processed : 'Unknown'}
        title={translations['voids_processed']}
        />
        <KpiCard
        primary={KPIs ? KPIs.NYB_Matched : 'Unknown'}
        title={translations['not_yet_built_matched']}
        />
        <KpiCard
        primary={KPIs ? KPIs.Percent_NYB_Matched : 'Unknown'}
        title={translations['percent.not_yet_built_matched']}
        />
        <KpiCard
        primary={KPIs ? KPIs.NEW_NYB : 'Unknown'}
        title={translations['not_yet_built_matched_identified']}
        />
        <KpiCard
        primary={KPIs ? KPIs.Existing_NYB : 'Unknown'}
        title={translations['existing_not_yet_built']}
        />
    </div>
    )
}

const LoadingSpinner = () => {
  return (
    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: 'auto auto'}}>
      <i className="fas fa-spinner fa-pulse fa-4x colour-gray"></i>
    </div>
  );
};

export default function AddressAlerts() {
  const localised = Intl.NumberFormat('en-GB');

  const [date] = useState(null)
  const [group, setGroup] = useState(null)
  const [groupings, setGroupings] = useState([])

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalMode, setModalMode] = useState(hasPermission('AA', 'DOWNLOAD') ? 'download' : null)

  const [tableOrGraph, setTableOrGraph] = useState('graph')
  const [data, setData] = useState(null)
  const [dataReady, setDataReady] = useState(false)
  const [graphData, setGraphData] = useState([])
  const [colours] = useState([
    "#445469",
    "#33d1ad",
    "#0d73b2",
    "#f19a14",
    "#d13358",
    "#b24c0d",
    "#DFBE99",
    "#C879FF",
    "#DEC5E3",
    "#0D1821",
    "#F9CB40"
  ])
  const [filters, setFilters] = useState({})
  const translations = useTranslations('address_alerts')

  const pageOptions = {
    title: translations['module_title'] || ''
  }

  const clearFilters = () => {
      setFilters({})
  }

  const filter = (key, val) => {
      setFilters({
          ...filters,
          [key]: val
      })
  }

  const queryData = useCallback(() => {
    if (!group) return
    setDataReady(false)
    request(true).get(endpoints.ADDRESS_ALERTS_DATA_SUMMARY, {
        params: {
            date: date,
            filter: filters,
            group: group?.target_column,
        },
        doesCancel: true,
    }).then(r => {
        setData(r.data)
        setDataReady(true)
    });
  }, [date, filters, group])

  const queryGroupings = () => {
    request(true).get(endpoints.ADDRESS_ALERTS_GROUPINGS, {
        doesCancel: true,
    }).then((data) => {
        if (!data && !data.data && !data.data.length) return
        setGroupings(data.data);
        const first = data.data.find(_ => _.default);
        if (first) {
            setGroup(first)
        } else {
            setGroup(data.data[0])
        }
    })
  }

  useEffect(() => {
    queryGroupings();
  }, [])

  useEffect(() => {
    queryData();
  }, [group, filters, date])

  useEffect(() => {
    //console.log(this.state.data);
    if (!data) {
        console.log('No Data to generate graph')
        return
    }

    let d = Object.keys(data.data).map(a => { return { ...data.data[a], Month: a } })
    console.log('d', d)

    const toFill = 12 - (d.length >= 12 ? 12 : d.length)

    d = d.concat(new Array(toFill).fill({}))

    setGraphData(d)
  }, [data])

  return (
    <Page {...pageOptions}>
        <div className="grid grid-gap-15">
            <AddressAlertsKPIs />
            <div className="aa">
                <div className="grid-overview">
                    <div className="left">
                        <div className="table-format">
                            <div className={`data-row group-list ${group?.target_column}-active`}>
                                <div className="data-row-title">
                                    Group by:
                                </div>
                                <div className="data-row-field">
                                    <DataGroupings group={group} setGroup={setGroup} groupings={groupings} />
                                    {/* <button className="Status-button" onClick={ () => setGroup('Status') }>
                                        NYB Status
                                    </button>
                                    <button className="Occupier-Score-button" onClick={ () => setGroup('Occupier_Score') }>
                                        Occupier Score
                                    </button>
                                    <button className="NYB-Age-Band-button" onClick={ () => setGroup('NYB_Age_Band') }>
                                        New Build Age Band
                                    </button> */}
                                    {/* <button onClick={ () => setGroup('Voids_Age_Band') }>
                                        NYB Band
                                    </button> */}
                                </div>
                            </div>
                            <div className="data-row display-type">
                                <select onChange={ e => setTableOrGraph(e.target.value) } value={ tableOrGraph }>
                                    <option value="table">
                                        Table
                                    </option>
                                    <option value="graph">
                                        Graph
                                    </option>
                                </select>
                                <ProductChartInfo path={window.location.pathname} />
                            </div>
                        </div>
                          {dataReady ?
                                <div className={`aa-table-or-graph using-${tableOrGraph}`}>
                                    {tableOrGraph === 'table' ?
                                        <table className="table borders">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        File Date
                                                    </th>
                                                    {
                                                        data.keys.map(key => {
                                                            return (
                                                                <th>
                                                                    <p>{key}</p>
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                    <th className="no-radius bold">
                                                        Total
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    graphData ? graphData.filter(_ => Object.keys(_).length > 0).map((row, index) => {
                                                        return (
                                                            <tr>
                                                                <td>{row.Month}</td>
                                                                {
                                                                    data.keys.map(key => {
                                                                        return (
                                                                            <td>{localised.format(row[key] || 0)}</td>
                                                                        )
                                                                    })
                                                                }
                                                                <td>{localised.format(data.keys.reduce((pre, cur) => pre += row[cur] || 0, 0))}</td>
                                                            </tr>
                                                        )
                                                    }) : null
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        dataReady ?
                                            <ResponsiveContainer>
                                                <BarChart
                                                    data={graphData}
                                                    margin={{ bottom: 20 }}
                                                    barSize={50}
                                                >
                                                    <Legend iconType="circle" iconSize={11} />
                                                    <XAxis interval={0} type="category" minTickGap={1} dataKey="Month" />
                                                    <YAxis type="number" allowDecimals={false} tick={{ fontSize: 10 }} tickFormatter={item => { return localised.format(item) }} />
                                                    <CartesianGrid vertical={false} />
                                                    {data.keys.map((item, index) => {
                                                        return (
                                                            <Bar key={Math.random()} dataKey={item} fill={colours[index]} stackId="a" />
                                                        )
                                                    })}
                                                    <Tooltip cursor={false} formatter={(value, name, props) => { return localised.format(value) }} />

                                                </BarChart>
                                            </ResponsiveContainer>
                                            : ''
                                    }
                                </div>
                                :
                                <LoadingSpinner />
                            }

                        </div>
                        <div className="right">
                            <div className="filters">
                                <div className="filter-title">
                                    {/* <i className="fas fa-caret-up"></i> */}
                                    <div className="title">Filters</div>
                                    <div className="clear" onClick={clearFilters}>Clear All</div>
                                </div>
                                <ProductDataFilter applyFilter={filter} filterState={filters} dataFilterEndpoint={endpoints.ADDRESS_ALERTS_DATA_FILTERS} />
                            </div>
                            <div className="grid grid-columns-2 grid-gap-10 mta">
                                {
                                    hasPermission('AA', 'DOWNLOAD') ? <LargeLinkButton
                                        textSize={12}
                                        iconSize={32}
                                        small={true}
                                        callback={() => { setModalMode('download'); setModalIsOpen(true) }}
                                        title="Download Return File"
                                        icon={'fas fa-cloud-download-alt'}
                                    /> : null
                                }
                                {
                                    hasPermission('AA', 'UPLOAD') ? <LargeLinkButton
                                        textSize={12}
                                        iconSize={32}
                                        small={true}
                                        callback={() => { setModalMode('upload'); setModalIsOpen(true) }}
                                        title="Issue Input File"
                                        icon={'fas fa-cloud-upload-alt'}
                                    /> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <S3Modal
                showModal={modalIsOpen}
                closeModal={() => setModalIsOpen(false)}
                modalMode={modalMode}
                listingUrl={endpoints.ADDRESS_ALERTS_FILE_GET}
                uploadUrl={endpoints.ADDRESS_ALERTS_FILE_UPLOAD}
            />
        </Page>
    )
}
