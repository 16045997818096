import React, { Component, useEffect } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './vacantCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class VacantCOTAlertsSearch extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: null,
                    customer: null,
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    translations: [],
                    exclusionReason: 'null',
                    exclusionFeedback: '',
                    workflowModalIsOpen: 'null',
                    exclusionModalIsOpen: 'null',
                    moveInModalStep: 1,
                    otherName: ''
                }
            }

            componentDidMount() {
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=vacant_cot_alerts').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                const {history} = this.props;

                const spid = history.location?.state?.spid;
                this.setState({accountNumber: spid}, () => {
                    if (this.state.accountNumber) {
                        history.replace() 
                        this.getData()
                    }
                })
            }

            accountNumberChange = (event) => {
                this.setState({
                    accountNumber: event.target.value
                });
            }

            getData = () => {
                if (!this.state.accountNumber) return;
                this.setState({customer: null, purchasable: null})
                request(true).get(`${this.props.history.location.pathname.indexOf('-uat') !== -1 ? endpoints.VACANT_COT_ALERTS_UAT_GET_CUSTOMER : endpoints.VACANT_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    // console.log(r.data.customer, r.data.customer ? '' : 'Customer Does Not Exist');
                    if (!r.data.customer) window.alert('Could not find SPID.');
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer && r.data.customer.Date_Bought === null,
                        shouldRefreshMap: true,
                        assigned_to: r.data.customer ? r.data.customer.assigned_to : null,
                        exclusionReason: r.data.customer ? r.data.customer.Occupier_Exclusion_Reason ? r.data.customer.Occupier_Exclusion_Reason : 'null' : 'null'
                    }, () => {
                        this.setState({ shouldRefreshMap: false });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = (occupierPurchaseType) => {
                request(true).post(`${this.props.history.location.pathname.indexOf('-uat') !== -1 ? endpoints.VACANT_COT_ALERTS_UAT_BUY_CUSTOMER : endpoints.VACANT_COT_ALERTS_BUY_CUSTOMER}`, {
                    "customer": this.state.customer.SPID,
                    "occupier_purchase_type": occupierPurchaseType,
                    "other_name": this.state.otherName
                }).then(r => {
                    this.setState({
                        customer: r.data.customer,
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null,
                        workflowModalIsOpen: 'null',
                    });
                }).catch(e => {
                    console.log(e);
                })
            }

            
            resetMoveInModal = () => {
                this.setState({
                    workflowModalIsOpen: 'null',
                    moveInModalStep: 1,
                    otherName: ''
                })
            }

            openWorkflowBuyModal = () => {
                this.setState({
                    workflowModalIsOpen: 'true'
                })
            }

            openExclusionModal = () => {
                this.setState({
                    exclusionModalIsOpen: 'true'
                })
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.SPID) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(this.props.history.location.pathname.indexOf('-uat') !== -1 ? endpoints.VACANT_COT_ALERTS_UAT_EXCLUDE_CUSTOMER : endpoints.VACANT_COT_ALERTS_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.SPID,
                        Occupier_Exclusion_Reason: this.state.exclusionReason,
                        feedback: this.state.exclusionFeedback,
                    }).catch(e => {
                        console.error(e);
                    });
                });
                this.setState({exclusionModalIsOpen: 'null'})
            }

            searchKeyDown = (e) => {
                if (e.which === 13) {
                    this.getData();
                }
            }

            componentWillUnmount() {
                useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
            }

            updateStatus = (event) => {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        status: event.target.value
                    }
                }, () => {
                    request(true).post(`${this.props.history.location.pathname.indexOf('-uat') !== -1 ? endpoints.VACANT_COT_ALERTS_UAT_UPDATE_STATUS : endpoints.VACANT_COT_ALERTS_UPDATE_STATUS}`, {
                        customer: this.state.customer.SPID,
                        status: this.state.customer.status
                    }).catch(e => {
                        console.log(e);
                    })
                })
            }

            updateCotDate = (date) => {
                const originalDate = customer.COT_Date
                const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                setCustomer({
                    ...customer,
                    COT_Date: formattedDate
                })
                request(true).post(this.props.history.location.pathname.indexOf('-uat') !== -1 ? endpoints.VACANT_COT_ALERTS_UAT_UPDATE_COT_DATE : endpoints.VACANT_COT_ALERTS_UPDATE_COT_DATE, {
                    customer: customer.SPID,
                    date: formattedDate
                }).catch(e => {
                    setCustomer({
                        ...customer,
                        COT_Date: originalDate
                    })
                    console.log(e)
                    window.alert("Failed to update COT Date")
                })
            }

            updateAdcGroup = (event) => {
                this.setState({
                    customer: {
                        ...this.state.customer,
                        ADC_Group: event.target.value
                    }
                }, () => {
                    request(true).post(`${this.props.history.location.pathname.indexOf('-uat') !== -1 ? endpoints.VACANT_COT_ALERTS_UAT_UPDATE_ADC_GROUP : endpoints.VACANT_COT_ALERTS_UPDATE_ADC_GROUP}`, {
                        customer: this.state.customer.SPID,
                        ADC_Group: this.state.customer.ADC_Group
                    }).catch(e => {
                        console.log(e);
                    })
                })
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-sales-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title={`${this.state.translations['module_title'] ? this.state.translations['module_title'] : ''} ${this.props.history.location.pathname.indexOf('-uat') !== -1 ? 'UAT' : ''} - Search`} iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to={`/portal/vacant-cot-alerts${this.props.history.location.pathname.indexOf('-uat') !== -1 ? '-uat' : ''}/`}>
                                        To Overview
                                    </Link>
                                    {(hasPermission(this.props.history.location.pathname.indexOf('-uat') !== -1 ? 'VACANT_COT_UAT' : 'VACANT_COT', 'WORKFLOW')) ?
                                        <Link className="navigate" to={`/portal/vacant-cot-alerts${this.props.history.location.pathname.indexOf('-uat') !== -1 ? '-uat' : ''}/workflow` }>
                                            To Workflow
                                        </Link>
                                        : null}
                                    {(hasPermission(this.props.history.location.pathname.indexOf('-uat') !== -1 ? 'VACANT_COT_UAT' : 'VACANT_COT', 'DASHBOARD')) ?
                                        <Link className="navigate" to={`/portal/vacant-cot-alerts${this.props.history.location.pathname.indexOf('-uat') !== -1 ? '-uat' : ''}/dashboard` }>
                                            To Dashboard
                                        </Link>
                                    : null}
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vacantCOTAlerts">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <LeftColumn state={this.state}>
                                            <div className="data-row" style={{gridTemplateColumns: "0fr 2fr 1.15fr"}}>
                                                <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['spid'] : '' }
                                                </div>
                                                <input type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } onChange={ this.accountNumberChange } onKeyPress={ this.searchKeyDown }/>
                                                <button className="search-button" onClick={ this.getData }>
                                                    Search
                                                </button>
                                            </div>
                                        </LeftColumn>
                                    </div>
                                    <RightColumn state={this.state} updateAdcGroup={this.updateAdcGroup}>
                                        { (!hasPermission(this.props.history.location.pathname.indexOf('-uat') !== -1 ? 'VACANT_COT_UAT' : 'VACANT_COT', 'PURCHASE')) && this.state.purchasable ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Occupier Moved In
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable} onClick={ this.openWorkflowBuyModal }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Occupier Moved In" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <select style={{height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5}} onChange={_ => { this.setState({exclusionReason: _.target.value}); this.openExclusionModal() }} value={this.state.exclusionReason}>
                                            { session.company.exclusion_reasons_type === 'Water' ?
                                                <>
                                                    <option value={'null'}>Select an exclusion reason</option>
                                                    <option value={'Existing Live Account'}>Existing Live Account</option>
                                                    <option value={'Multiple Occupier/LL Query'}>Multiple Occupier/LL Query</option>
                                                    <option value={'Address/Data Quality Issue'}>Address/Data Quality Issue</option>
                                                    <option value={'Previous Occupier Provided'}>Previous Occupier Provided</option>
                                                    <option value={'Adjacent Occupier Provided'}>Adjacent Occupier Provided</option>
                                                    <option value={'De-registration'}>De-registration</option>
                                                    <option value={'Account Already Open - Same Occupier'}>Account Already Open - Same Occupier</option>
                                                    <option value={'Account Already Open - Different Occupier'}>Account Already Open - Different Occupier</option>
                                                    <option value={'Other Retailer'}>Other Retailer</option>
                                                    <option value={'No Consumption'}>No Consumption</option>
                                                    <option value={'Insufficient Information'}>Insufficient Information</option>
                                                    <option value={'Other'}>Other</option>
                                                </>
                                            : session.company.exclusion_reasons_type === 'Energy' ?
                                                <>
                                                    <option value={'null'}>Select an exclusion reason</option>
                                                    <option value={'No COT: Current Occupier'}>No COT: Current Occupier</option>
                                                    <option value={'No COT: Name Correction'}>No COT: Name Correction</option>
                                                    <option value={'No COT: Now Vacant'}>No COT: Now Vacant</option>
                                                    <option value={'No COT: Unable to Confirm COT Date'}>No COT: Unable to Confirm COT Date</option>
                                                    <option value={'Multiple Occupier/LL Query'}>Multiple Occupier/LL Query</option>
                                                    <option value={'Address/Data Quality Issue'}>Address/Data Quality Issue</option>
                                                    <option value={'Previous Occupier Provided'}>Previous Occupier Provided</option>
                                                    <option value={'Adjacent Occupier Provided'}>Adjacent Occupier Provided</option>
                                                    <option value={'De-registration'}>De-registration</option>
                                                    <option value={'Awaiting Customer Response'}>Awaiting Customer Response</option>
                                                    <option value={'No Contact Info Provided or Located'}>No Contact Info Provided or Located</option>
                                                    <option value={'Contact Info Available But No Contact Achieved'}>Contact Info Available But No Contact Achieved</option>
                                                </>
                                            : ''}
                                        </select>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                Status
                                            </div>
                                            <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={this.updateStatus} value={this.state.customer?.status ?? "New"}>
                                                <option value="New">New</option>
                                                <option value="Complex">Complex</option>
                                                <option value="In progress">In progress</option>
                                            </select>
                                        </div>
                                    </RightColumn>
                                    <TitleOwnerRow state={{translations: this.state.translations, customer: this.state.customer, updateCotDate: this.updateCotDate}} />
            
                                </div>
                                <div className="secondary-grid">
                                    {/* <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null }/> */}
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <GoogleEvidence data={this.state.customer} />
                                        <CompaniesHouseEvidence data={this.state.customer} />
                                        <YellComEvidence data={this.state.customer} />
                                        <AddressEvidence data={this.state.customer} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <FoodStandardsEvidence data={this.state.customer} />
                                        <BTDirectoryEvidence data={this.state.customer} />
                                        <CharityCommisionEvidence data={this.state.customer} />
                                        <DVSAEvidence data={this.state.customer} />
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                                        <CQCEvidence data={this.state.customer} />
                                        <JustEatEvidence data={this.state.customer} />
                                        <One92Evidence data={this.state.customer} />
                                        <FacebookEvidence data={this.state.customer} />
                                    </div>
                                </div>

                                <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px' }}>
                                    <table className="table borders squish super-small-text extra-row-padding">
                                        <thead>
                                            <tr>
                                                <th style={{textAlign: 'left'}}>
                                                    Days
                                                </th>
                                                <th>
                                                    Opening Times
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                                                    return (
                                                        <tr>
                                                            <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                                            <td style={{minWidth: "unset"}}>{ this.state.customer?.Google_Opening_Hours ? JSON.parse(this.state.customer.Google_Opening_Hours.replaceAll('\'', '"'))[day] : null }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div style={{height: 'auto'}}>
                                    { this.state.showMap ?
                                        <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    :
                                        <>
                                            <div className="map hidden" onClick={ () => { if (this.state.customer) { this.setState({ showMap: true })}} }>
                                                { this.state.customer ? 'Click to show map' : '' }
                                            </div>
                                        </>
                                    }
                                    </div>
                                </div>
                            </div>
                            <div style={{ overflowY: 'auto', maxHeight: '70vh' }}>
                                <table className='table borders squish super-small-text extra-row-padding' style={{  }}>
                                    <thead style={{position: 'sticky', top: '0px'}}>
                                        <tr>
                                            <th>{this.state.translations['spid']}</th>
                                            <th>{this.state.translations['company_name']}</th>
                                            <th>{this.state.translations['client_address']}</th>
                                            <th>Total Debt</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.customer && this.state.customer.Matching_Customers.length > 0 ?
                                                this.state.customer.Matching_Customers.map(match => {
                                                    return (
                                                        <tr>
                                                            <td>{match.SPID}</td>
                                                            <td>{match.Customer_Name}</td>
                                                            <td>{match.CMOS_Address_Single_Line}</td>
                                                            <td>{match.Account_Balance}</td>
                                                        </tr>
                                                    )
                                                })
                                            :
                                            <tr><td/><td/><td/><td/></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vacant_search_video_url}/>
                        <ReactModal 
                        className="card bulk-buy"
                        isOpen={this.state.exclusionModalIsOpen !== 'null'}>
                            <p>You have selected the following exclusion reason:</p>
                            <p><strong>{this.state.exclusionReason}</strong></p>
                            <div style={{ display: 'grid', marginBottom: '1em' }}>
                                <p>Additional Feedback:</p>
                                <textarea onChange={(event) => this.setState({exclusionFeedback: event.target.value.slice(0, 500)})} value={this.state.exclusionFeedback ?? ''} rows={5} style={{ resize: 'none' }}/>
                                <div style={{ marginLeft: 'auto', color: 'gray', fontSize: 12 }}>
                                {this.state.exclusionFeedback?.length ?? 0}/500
                                </div>
                            </div>
                            <div className='grid grid-columns-2 grid-gap-5'>
                                <button className='button compact background-red colour-white' onClick={() => this.setState({exclusionModalIsOpen: 'null'})}>Cancel</button>
                                <button className='button compact background-primary colour-white' onClick={() => this.excludeCustomer()}>Confirm</button>
                            </div>
                        </ReactModal>
                        <ReactModal
                            className="card bulk-buy"
                            isOpen={this.state.workflowModalIsOpen !== 'null'}>
                            {
                                this.state.moveInModalStep === 1 ?
                                    <>
                                        <p>You have selected to move in an occupier </p>
                                        <p>Select either Cancel, IDenteq for identified name or Other for an other name </p>
                                        <div className='grid grid-columns-3 grid-gap-5'>
                                            <button className='button compact background-red colour-white' onClick={this.resetMoveInModal}>Cancel</button>
                                            {session.company.vaca_confirmation_screen && this.props.history.location.pathname.includes(session.company.vaca_confirmation_screen.replaceAll('_', '-') + '/') ?
                                                <button
                                                    className='button compact background-primary colour-white'
                                                    onClick={() => {
                                                        console.log('Source Component - onClick', this.state.customer?.SPID, this.props.location.pathname);

                                                        this.props.history.push({
                                                            pathname: `/portal/vacant-cot-alerts${this.props.history.location.pathname.indexOf('-uat') !== -1 ? '-uat' : ''}/confirmation/`,
                                                            state: { spid: this.state.customer?.SPID, prevPath: this.props.history.location.pathname }
                                                        })
                                                    }
                                                    }
                                                >
                                                    Identeq
                                                </button>
                                                :
                                                <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('IDenteq')}>IDenteq</button>
                                            }
                                            <button className='button compact background-primary colour-white' onClick={() => this.setState({moveInModalStep: 2})}>Other</button>
                                        </div>
                                    </>
                                : this.state.moveInModalStep === 2 &&
                                    <>
                                        <p>Please confirm your choice</p>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'min-content auto', marginBottom: '1em', gap: '1em  ' }}>
                                            <label style={{ whiteSpace: 'nowrap' }}>Other Name: </label>
                                            <input type="text" className="data-row-field" value={this.state.otherName ?? ''} onChange={(event) => this.setState({otherName: event.target.value})} />
                                        </div>
                                        <div className='grid grid-columns-2 grid-gap-5'>
                                            <button className='button compact background-red colour-white' onClick={this.resetMoveInModal}>Cancel</button>
                                            <button className='button compact background-primary colour-white' onClick={() => this.purchaseCustomer('Other')}>Confirm</button>
                                        </div>
                                    </>
                            }
                        </ReactModal>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

export function DeedPDFViewer({ customer, refresh }) {
    useEffect(() => {
        if (!customer) return;
        useWSProduct('void_sales_alerts', 'listen', `_${customer.Account_Number}`, () => {// eslint-disable-line
            refresh()
        })

        return () => {
            // cleanup
            useWSProduct('void_sales_alerts', 'unregister', `_${customer.Account_Number}`)// eslint-disable-line
        }
    })
    return (
        customer && customer.Bought_By ? customer.DeedPDF ? <div>
            <iframe title="Deed PDF" style={{minHeight: 400, minWidth: '100%'}} src={customer.DeedPDF} />
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>We're processing your deed purchase, it'll appear here automatically once available.</p>
        </div> : <div style={{height: '100%', minHeight: 400, backgroundColor: 'lightgray', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{color: 'white', fontWeight: 700}}>Deed available after purchase.</p>
        </div>
    )
}
