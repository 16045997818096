import React, { useCallback, useEffect } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import ReactSelect from 'react-select'
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import ReactModal from 'react-modal';
import session from '../../../stores/session';
import moment from 'moment'
import { useTranslations } from '../../../helpers/hooks';

const FilterButton = ({ onFilterChange, filter }) => {
  const [showFilter, setShowFilter] = useState(false)

  const [fromDate, setFromDate] = useState(filter.fromDate ? moment(filter.fromDate) : moment().startOf('month').toDate());
  const [toDate, setToDate] = useState(filter.toDate ? moment(filter.toDate) : moment().endOf('month').toDate());
  const [wholesaler, setWholesaler] = useState(filter.wholesaler || [])
  
  const [wholesalerList, setWholesalerList] = useState([])

  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_WHOLESALER).then(e => {
      setWholesalerList(e.data)
    })
  }, [])

  const onClick = useCallback(() => {
    setShowFilter(_ => !_)
  }, [setShowFilter])

  useEffect(() => {
    if (!wholesaler) return
    const data = {
      wholesaler: wholesaler.length ? wholesaler : undefined,
      fromDate,
      toDate
    }
    console.log(data)
    if (!onFilterChange) return
    onFilterChange(data)
  }, [wholesaler, fromDate, toDate, onFilterChange])

  return [
    <span style={{cursor: 'pointer'}} onClick={onClick}><span className='fas fa-filter-list'></span> Filter</span>,
    <ReactModal
      className="card bulk-buy"
      isOpen={showFilter}
      onRequestClose={onClick}>
        <div className='flex-column grid-gap-15'>

          <h2>Filter</h2>
          
          <DatePicker label="From Date" onChange={_ => setFromDate(_.toDate())} value={fromDate} />
          <DatePicker label="To Date" onChange={_ => setToDate(_.toDate())} value={toDate} />

          <div>
            <label className='MuiFormLabel-root' style={{fontSize: 14}}>Wholesaler</label>
            <ReactSelect 
            isMulti={true} 
            options={wholesalerList} 
            onChange={_ => setWholesaler(_.map(x => x.value))} 
            value={wholesaler.map(_ => { return {value: _, label: _} })} />
          </div>

          <button className='button compact background-primary colour-white' onClick={onClick}>Confirm</button>

        </div>
    </ReactModal>
  ]
}

const OVDashboardMonthAndYear = () => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_MONTH_AND_YEAR, {
      params: {
        wholesaler: 'a'
      }
    }).then(e => {
      setData(e.data)
    })
  }, [])
  return (
    <div>
      <table className='table squish smaller-text'>
        <thead>
          <tr>
            <th colSpan={12}>Team Workflow Allocation</th>
          </tr>
          <tr>
            <th>
              User Name
            </th>
            {/* <th>
              User ID
            </th> */}
            <th>Allocated <br /> No Occupier</th>
            <th>Allocated <br /> Occupying</th>
            <th>Allocated <br /> Moved Out</th>
            <th>Allocated <br /> Change of Occupier</th>
            <th>Allocated <br /> Proprietor</th>
            <th>Allocated <br /> Different Reg Occupier</th>
            <th>Allocated <br /> Different Occupier</th>
            <th>Allocated Total</th>
          </tr>
        </thead>
        <tbody>
          {
            data.grouped.map(_row => {
              return (
                <tr>
                  <td>{_row.Assigned_To_Name}</td>
                  {/* <td>{_row.Assigned_To}</td> */}
                  <td>{_row['No Occupier']}</td>
                  <td>{_row['Occupying']}</td>
                  <td>{_row['Moved Out']}</td>
                  <td>{_row['Change of Occupier']}</td>
                  <td>{_row['Proprietor']}</td>
                  <td>{_row['Different Reg Occupier']}</td>
                  <td>{_row['Different Occupier']}</td>
                  <td>{_row['Total']}</td>
                </tr>
              )
            })
          }
          <tr>
            <td>Totals</td>
            <td>{data.totals['No Occupier']}</td>
            <td>{data.totals['Occupying']}</td>
            <td>{data.totals['Moved Out']}</td>
            <td>{data.totals['Change of Occupier']}</td>
            <td>{data.totals['Proprietor']}</td>
            <td>{data.totals['Different Reg Occupier']}</td>
            <td>{data.totals['Different Occupier']}</td>
            <td>{data.totals['Total']}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
  

const OVDashboardMoveInsPerformed = () => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_MOVEINS_PERFORMED, {
      params: {
        wholesaler: 'a'
      }
    }).then(e => {
      setData(e.data)
    })
  }, [])
  return (
    <div>
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th>
                  {/* <input type="date" />
                  <span> to </span>
                  <input type="date" /> */}
                </th>
                <th colSpan={8}>Move Ins Performed By Occupier Confidence YTD</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                {/* <th>
                  User ID
                </th> */}
                <th>Allocated <br /> No Occupier</th>
                <th>Allocated <br /> Occupying</th>
                <th>Allocated <br /> Moved Out</th>
                <th>Allocated <br /> Change of Occupier</th>
                <th>Allocated <br /> Proprietor</th>
                <th>Allocated <br /> Different Reg Occupier</th>
                <th>Allocated <br /> Different Occupier</th>
                <th>Allocated Total</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row.Bought_By}</td>
                      {/* <td>{_row.Assigned_To}</td> */}
                      <td>{_row['No Occupier']}</td>
                      <td>{_row['Occupying']}</td>
                      <td>{_row['Moved Out']}</td>
                      <td>{_row['Change of Occupier']}</td>
                      <td>{_row['Proprietor']}</td>
                      <td>{_row['Different Reg Occupier']}</td>
                      <td>{_row['Different Occupier']}</td>
                      <td>{_row['Total']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Totals</td>
                <td>{data.totals['No Occupier']}</td>
                <td>{data.totals['Occupying']}</td>
                <td>{data.totals['Moved Out']}</td>
                <td>{data.totals['Change of Occupier']}</td>
                <td>{data.totals['Proprietor']}</td>
                <td>{data.totals['Different Reg Occupier']}</td>
                <td>{data.totals['Different Occupier']}</td>
                <td>{data.totals['Total']}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

const OVDashboardTeamPerformance = () => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_MOVEINS_TEAM_PERFORMED, {
      params: {
        wholesaler: 'a'
      }
    }).then(e => {
      setData(e.data)
    })
  }, [])
  return (
    <div>
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th>
                  {/* <input type="date" />
                  <span> to </span>
                  <input type="date" /> */}
                </th>
                <th colSpan={6}>Team Performance YTD</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                <th>Moved In</th>
                <th>Excluded</th>
                <th>Completed</th>
                <th>Days Worked (i)</th>
                <th>Average Daily Completions</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row['Bought_By']}</td>
                      <td>{_row['Moved_In_Number']}<br />({_row['Moved_In_Split']}%)</td>
                      <td>{_row['Excluded_Number']}<br />({_row['Excluded_Split']}%)</td>
                      <td>{_row['Completed_Number']}<br />({_row['Completed_Split']}%)</td>
                      {/* <td>{_row['Total']}</td> */}
                      <td>{_row['Working_Days']}</td>
                      <td>{_row['Avg_Daily_Completions']}</td>
                      {/* <td>{_row['YTD']}</td> */}
                    </tr>
                  )
                })
              }
              <tr>
                <td>Totals</td>
                <td>{data.totals['Moved_In_Number']}<br />({data.totals['Moved_In_Split']}%)</td>
                <td>{data.totals['Excluded_Number']}<br />({data.totals['Excluded_Split']}%)</td>
                <td>{data.totals['Completed_Number']}<br />({data.totals['Completed_Split']}%)</td>
                {/* <td>{data.totals['Total']}</td> */}
                <td>{data.totals['Working_Days']}</td>
                <td>{data.totals['Avg_Daily_Completions']}</td>
                {/* <td>{data.totals['YTD']}</td> */}
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

const OVDashboardTeamWorkflow = ({ filter, setFilter }) => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_WORKFLOW, {
      params: filter
    }).then(e => {
      setData(e.data)
    }).catch(e => {
      window.alert('Please adjust your filter for Team Workflow Allocation')
    })
  }, [filter])
  
  return (
    <div>
      <table className='table squish smaller-text'>
        <thead>
          <tr>
            <th>
              <FilterButton key={'twa_filter'} onFilterChange={setFilter} filter={filter} />
            </th>
            <th colSpan={8}>Team Workflow Allocation</th>
          </tr>
          <tr>
            <th>
              User Name
            </th>
            <th>No Occupier</th>
            <th>Occupying</th>
            <th>Moved Out</th>
            <th>Change of Occupier</th>
            <th>Proprietor</th>
            <th>Different Reg Occupier</th>
            <th>Different Occupier</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {
            data.grouped.map(_row => {
              return (
                <tr>
                  <td>{_row.Assigned_To_Name}</td>
                  <td>{_row['No Occupier']}</td>
                  <td>{_row['Occupying']}</td>
                  <td>{_row['Moved Out']}</td>
                  <td>{_row['Change of Occupier']}</td>
                  <td>{_row['Proprietor']}</td>
                  <td>{_row['Different Reg Occupier']}</td>
                  <td>{_row['Different Occupier']}</td>
                  <td>{_row['Total']}</td>
                </tr>
              )
            })
          }
          <tr>
            <td>Totals</td>
            <td>{data.totals['No Occupier']}</td>
            <td>{data.totals['Occupying']}</td>
            <td>{data.totals['Moved Out']}</td>
            <td>{data.totals['Change of Occupier']}</td>
            <td>{data.totals['Proprietor']}</td>
            <td>{data.totals['Different Reg Occupier']}</td>
            <td>{data.totals['Different Occupier']}</td>
            <td>{data.totals['Total']}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const OVDashboardTeamPerformanceDays = ({filter}) => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_PERFORMANCE, {
      params: filter
    }).then(e => {
      setData(e.data)
    }).catch(e => {
      window.alert('Please adjust your filter for Team Performance (lower)')
    })
  }, [filter])
  return (
    <div>
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th>
                </th>
                <th colSpan={6}>Team Performance</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                <th>Moved In</th>
                <th>Excluded</th>
                <th>Completed</th>
                <th>Days Worked (i)</th>
                <th>Average Daily Completions</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row['Bought_By']}</td>
                      <td>{_row['Moved_In_Number']}<br />({_row['Moved_In_Split']}%)</td>
                      <td>{_row['Excluded_Number']}<br />({_row['Excluded_Split']}%)</td>
                      <td>{_row['Completed_Number']}<br />({_row['Completed_Split']}%)</td>
                      {/* <td>{_row['Total']}</td> */}
                      <td>{_row['Working_Days']}</td>
                      <td>{_row['Avg_Daily_Completions']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Totals</td>
                <td>{data.totals['Moved_In_Number']}<br />({data.totals['Moved_In_Split']}%)</td>
                <td>{data.totals['Excluded_Number']}<br />({data.totals['Excluded_Split']}%)</td>
                <td>{data.totals['Completed_Number']}<br />({data.totals['Completed_Split']}%)</td>
                {/* <td>{data.totals['Total']}</td> */}
                <td>{data.totals['Working_Days']}</td>
                <td>{data.totals['Avg_Daily_Completions']}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

const OVDashboardMoveInsPerformedByOccupierConfidence = ({filter}) => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_CONFIDENCE, {
      params: filter
    }).then(e => {
      setData(e.data)
    }).catch(e => {
      window.alert('Please adjust your filter for Move Ins Performed By Occupier Confidence')
    })
  }, [filter])
  return (
    <div>
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th>
                </th>
                <th colSpan={8}>Move Ins Performed By Occupier Confidence</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                <th>No Occupier</th>
                <th>Occupying</th>
                <th>Moved Out</th>
                <th>Change of Occupier</th>
                <th>Proprietor</th>
                <th>Different Reg Occupier</th>
                <th>Different Occupier</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row.Assigned_To_Name}</td>
                      <td>{_row['No Occupier']}</td>
                      <td>{_row['Occupying']}</td>
                      <td>{_row['Moved Out']}</td>
                      <td>{_row['Change of Occupier']}</td>
                      <td>{_row['Proprietor']}</td>
                      <td>{_row['Different Reg Occupier']}</td>
                      <td>{_row['Different Occupier']}</td>
                      <td>{_row['Total']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Totals</td>
                <td>{data.totals['No Occupier']}</td>
                <td>{data.totals['Occupying']}</td>
                <td>{data.totals['Moved Out']}</td>
                <td>{data.totals['Change of Occupier']}</td>
                <td>{data.totals['Proprietor']}</td>
                <td>{data.totals['Different Reg Occupier']}</td>
                <td>{data.totals['Different Occupier']}</td>
                <td>{data.totals['Total']}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

const OVDashboardRefreshCompletionPerformance = ({filter}) => {
  const [data, setData] = useState({
    grouped: [],
    totals: {}
  })
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DASHBOARD_REFRESH, {
      params: filter
    }).then(e => {
      setData(e.data)
    }).catch(e => {
      window.alert('Please adjust your filter for Refresh Completion Performance')
    })
  }, [filter])
  return (
    <div>
        <div>
          <table className='table squish smaller-text'>
            <thead>
              <tr>
                <th>
                </th>
                <th colSpan={6}>Refresh Completion Performance</th>
              </tr>
              <tr>
                <th>
                  User Name
                </th>
                <th>File Date - 3</th>
                <th>File Date - 2</th>
                <th>File Date - 1</th>
                <th>File Date</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {
                data.grouped.map(_row => {
                  return (
                    <tr>
                      <td>{_row['Assigned_To_Name']}</td>
                      <td>{_row['Date_3']}</td>
                      <td>{_row['Date_2']}</td>
                      <td>{_row['Date_1']}</td>
                      <td>{_row['Date_0']}</td>
                      <td>{_row['Total']}</td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>Totals</td>
                <td>{data.totals['Date_3']}</td>
                <td>{data.totals['Date_2']}</td>
                <td>{data.totals['Date_1']}</td>
                <td>{data.totals['Date_0']}</td>
                <td>{data.totals['Total']}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  )
}

export default view(function OccupierValidationDashboard() {
    const [filter, setFilter] = useState({})
    const translations = useTranslations('occupier_validation')

    return (
      <GenericPageContainer
      title={`${translations['module_title'] || ''} - Dashboard`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      containerWidth={'90vw'}
      titleRight={<>
          <Link className="navigate" to="/portal/occupier-validation/">
              To Overview
          </Link>
          <Link className="navigate" to="/portal/occupier-validation/search">
            To Search
        </Link>
        { (hasPermission('OCCUPIER_VALIDATION', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/occupier-validation/workflow">
                To Workflow
            </Link>
        : null }
      </>}
      >
  
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
          <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
            <OVDashboardMonthAndYear />
          </div>
          <OVDashboardMoveInsPerformed />
          <OVDashboardTeamPerformance />
  
          <OVDashboardTeamWorkflow filter={filter} setFilter={setFilter} />
          <OVDashboardTeamPerformanceDays filter={filter} />
          <OVDashboardMoveInsPerformedByOccupierConfidence filter={filter} />
          <OVDashboardRefreshCompletionPerformance filter={filter} />
        </div>
  
      </GenericPageContainer>
    )
  })
